<template>
  <div class="row-history">
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item input">
                  <el-input class="input" placeholder="请输入桩号" v-model="number"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <el-main>
        <el-table :data="tableData" style="width: 100%" ref="table" row-key="id" @selection-change="selectionChange" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
          <el-table-column fixed type="selection" align="center"></el-table-column>
          <el-table-column fixed prop="pileDescribe" label="桩号" min-width='70px' align='center' show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="deviceKey" label="桩机号" min-width='120px' align='center' show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="beginTime" label="开始时间" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.beginTime|datafilters }}
            </template>
          </el-table-column>
          <el-table-column prop="endTime" label="结束时间" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.endTime|datafilters }}
            </template>
          </el-table-column>
          <el-table-column prop="depth" label="行程(m)" min-width='80px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.depth|numfilters }}
            </template>
          </el-table-column>
          <el-table-column prop="designLength" label="桩长(m)" min-width='80px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.designLength|numfilters }}
            </template>
          </el-table-column>
          <el-table-column prop="harmerTimes" label="锤击次数" min-width='100px' align='center' show-overflow-tooltip>
            <!-- <template slot-scope="scope">
                        {{ scope.row.cumulativeAsh|numfilters }}
                    </template> -->
          </el-table-column>
          <el-table-column prop="maxAngle" label="最大斜度" min-width='100px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.maxAngle|num1filters }}
            </template>
          </el-table-column>
          <el-table-column prop="pileTime" label="成桩时间(min)" min-width='108px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.pileTime|minfilters }}
            </template>
          </el-table-column>
          <el-table-column fixed="right">
            <template slot-scope="scope">
              <div class="m-edit-btns">
                <div class="item">
                  <a href="javascript:" class="btn" @click="handleCommand(scope.$index)">详情</a>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
    <!-- 弹出框 -->
    <el-dialog :visible.sync="centerDialogVisible" class="m-pop m-pop-detail">
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
        <!-- <div class="right">
          <el-select class="g-select-table" v-model="selectTable" placeholder="请选择记录表下载">
            <el-option v-for="item in selectTableList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
      </div>
      <div class="dialog-content">
        <div class="m-tab-table m-tab-detail" v-show="tab2 === 0">
          <div class="m-table-detail">
            <!-- 详细数据 -->
            <div class="detailitem">
              <h3>详细数据</h3>
              <div class="box">
                <ul class="ul-detail">
                  <li class="s1">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">桩号</div>
                        <div class="value">{{selectItem.pileDescribe}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">桩机编号</div>
                        <div class="value">{{selectItem.deviceKey}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">开始时间</div>
                        <div class="value">{{selectItem.beginTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">结束时间</div>
                        <div class="value">{{selectItem.endTime|datafilters}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="s2">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">行程(m)</div>
                        <div class="value">{{selectItem.depth|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">配桩长度</div>
                        <div class="value">{{selectItem.designLength}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">最大斜度</div>
                        <div class="value">{{selectItem.maxAngle|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">桩端锤击次数</div>
                        <div class="value">{{selectItem.num}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="s3">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">配桩数量</div>
                        <div class="value">{{selectItem.designPileAmount}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">成桩时间(min)</div>
                        <div class="value">{{selectItem.pileTime|minfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">锤击次数</div>
                        <div class="value">{{selectItem.harmerTimes}}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 段数据 -->
            <br />
            <h3>段数据列表</h3>
            <el-table ref="saveTable" class="m-table-save m_table_save_height" :data="selectItem.data" style="width: 100%;margin-top:20px" height="28rem">
              <el-table-column type="index" label="序号" width="100px" align='center'>
              </el-table-column>
              <el-table-column property="startDay" label="时间" align='center'>
              </el-table-column>
              <el-table-column property="partDeep" label="行程(m)" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partDeep|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partHarmerTimes" label="段锤击数" align='center'>
              </el-table-column>
              <el-table-column property="partTimet" label="段时长(s)" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partTime|numfilters }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 1">
          <div class="m-pop-echart">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-footer class="m-selectAll">
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,sizes,jumper" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import moment from "moment";
import { historyList } from "@/api/api"
import gTabs from "@/components/gTabs";
import * as echarts from "echarts";
export default {
  components: {
    gTabs
  },
  props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'handleCurrentChange', 'toLastPage', 'toFirstPage', 'pileDescribe'],
  data() {
    return {
      totalChecked: false,
      startDate: '',
      endDate: '',
      number: "",
      deviceKey: '',
      echartsdata: [],
      selectItem: [],
      centerDialogVisible: false,
      tab2: 0,
      tab2List: [
        {
          name: "详细内容",
        },
        {
          name: "图表曲线",
        },
      ],
      selectTable: "记录报表下载",
      selectTableList: [
        {
          label: "记录报表下载",
          value: "0",
        },
        {
          label: "记录报表下载1",
          value: "1",
        },
      ],
      totalNum: 0,
      inquery: []
    }
  },
  filters: {
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val) {
      return parseFloat(val).toFixed(2)
    },
    minfilters(val) {
      return parseInt(val / 60)
    },
    num1filters(val) {
      return parseFloat(val).toFixed(1) + '%'
    }
  },
  watch: {
    centerDialogVisible(val, oldval) {
      if (val == false) {
        this.tab2 = 0
        if (this.myChart01 == undefined) return
        this.myChart01.dispose()
      }
    }
  },
  mounted() {
    this.deviceKey = this.device
  },
  methods: {

    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //选择设备查询
      //将修改的key值传到主页面
      //在主页面改为传过来的值
      //在主页面写一个方法接受传过来的值

      historyList('CP', 0, this.deviceKey, this.pageSize, this.number, this.startDate, this.endDate).then((res) => {
        this.inquery = res.content;
        this.totalNum = res.totalElements;
        this.$emit('changeDevicekey', this.deviceKey, this.number, this.startDate, this.endDate, this.inquery, this.totalNum)
        loading.close()
      });
      setTimeout(() => {
        loading.close()
      }, 6000)
    },
    resetClick() {
      this.deviceKey = "";
      this.number = '', this.startDate = '', this.endDate = ''
    },

    initEchart01() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep.toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partHarmerTimes.toFixed(3) + ''];
      });
      let option = {
        title: {
          text: "行程-锤击数曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "锤击数"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
        },
        yAxis: [
          {
            name: "深度(m)          ",
            type: "value",
            inverse: true,
            nameLocation: "end", // 设置坐标轴名称显示位置
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: "          锤击数",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
            splitLine: {
              show: false
            }
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "锤击数",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart01.setOption(option);
    },
    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index === 1) {
        this.$nextTick(() => {
          this.initEchart01();
          window.onresize = function () {
            self.myChart01.resize();
          };
        });
      }
    },
    // 详情点击
    handleCommand(index) {
      // 数据暂存在selectItem中
      this.selectItem = this.tableData[index];
      this.print = this.tableData[index].print  //将数据保存在print里边
      this.centerDialogVisible = true; //显示弹出框
      this.selectItem.num = Math.floor(Math.random() * 10 + 1)
      var day = this.selectItem.beginTime
      var time
      for (var i = 0; i < this.tableData[index].data.length; i++) {
        day += this.tableData[index].data[i].partTime
        time = moment(day * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.echartsdata.push(time)
      }
      var startTime = this.selectItem.beginTime
      for (var j = 0; j < this.selectItem.data.length; j++) {
        this.selectItem.data[j].startDay = moment(startTime * 1000).format('YYYY-MM-DD HH:mm:ss')
        startTime += this.selectItem.data[j].partTime
      }
    },
    // 选中发生变化
    selectionChange(e) {
      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },
  }
}
</script>
<style scoped>
.el-col-12 {
  width: 100%;
}
.ul-detail li {
  width: 33%;
}
/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.4rem 0 0;
}
.ul-detail li:first-child {
  padding-left: 1.6rem;
}
</style>
