<template>
  <div class="row-history">
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item input">
                  <el-input class="input" placeholder="请输入桩号" v-model="number"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <el-main>
        <el-table :data="tableData" style="width: 100%" ref="table" row-key="id" @selection-change="selectionChange" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
          <el-table-column fixed type="selection" align="center"></el-table-column>
          <el-table-column fixed prop="pileDescribe" label="桩号" min-width='70px' align='center' show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="deviceKey" label="桩机号" min-width='120px' align='center' show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="beginTime" label="开始时间" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.beginTime|datafilters }}
            </template>
          </el-table-column>
          <el-table-column prop="endTime" label="结束时间" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.endTime|datafilters }}
            </template>
          </el-table-column>
          <el-table-column prop="depth" label="行程(m)" min-width='70px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.depth|numfilters }}
            </template>
          </el-table-column>
          <el-table-column prop="designLength" label="桩长(m)" min-width='70px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.designLength|numfilters }}
            </template>
          </el-table-column>
          <el-table-column prop="maxPressure" label="最大压桩力(MPa)" min-width='120px' align='center' show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="maxAngle" label="最大斜度(%)" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.maxAngle|numfilters(1) }}
            </template>
          </el-table-column>
          <el-table-column prop="pileTime" label="成桩时间(min)" min-width='110px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.pileTime|minfilters }}
            </template>
          </el-table-column>
          <el-table-column fixed="right">
            <template slot-scope="scope">
              <div class="m-edit-btns">
                <div class="item">
                  <a href="javascript:" class="btn" @click="handleCommand(scope.$index)">详情</a>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
    <!-- 弹出框 -->
    <el-dialog :visible.sync="centerDialogVisible" class="m-pop m-pop-detail">
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
        <div class="right">
          <el-button size="small" v-if="showButton" type="primary" round @click="downLoadImage">图片下载</el-button>
        </div>
      </div>
      <div class="dialog-content">
        <div class="m-tab-table m-tab-detail" v-show="tab2 === 0">
          <div class="m-table-detail">
            <!-- 详细数据 -->
            <div class="detailitem">
              <h3>详细数据</h3>
              <div class="box">
                <ul class="ul-detail">
                  <li class="s1">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">桩号</div>
                        <div class="value">{{selectItem.pileDescribe}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">桩机编号</div>
                        <div class="value">{{selectItem.deviceKey}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">开始时间</div>
                        <div class="value">{{selectItem.beginTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">结束时间</div>
                        <div class="value">{{selectItem.endTime|datafilters}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="s2">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">行程(m)</div>
                        <div class="value">{{selectItem.depth|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">配桩长度</div>
                        <div class="value">{{length}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">实际配桩长度</div>
                        <div class="value">{{actualLength}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">最大斜度</div>
                        <div class="value">{{selectItem.maxAngle|numfilters}}%</div>
                      </div>
                    </div>
                  </li>
                  <li class="s3">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">成桩时间(min)</div>
                        <div class="value">{{selectItem.pileTime|minfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">配桩数量</div>
                        <div class="value">{{selectItem.designPileAmount}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">实际配桩数量</div>
                        <div class="value">{{selectItem.pileAmount}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">最大桩压力(MPa)</div>
                        <div class="value">{{selectItem.maxPressure|numfilters}}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 段数据 -->
            <br />
            <h3>段数据列表</h3>
            <el-table ref="saveTable" class="m-table-save m_table_save_height" :data="selectItem.data" style="width: 100%;margin-top:20px" height="28em">
              <el-table-column type="index" label="序号" width="100px" align='center'>
              </el-table-column>
              <el-table-column property="startDay" label="时间" min-width="8.33%" align='center'>
              </el-table-column>
              <el-table-column property="partDeep" label="行程(m)" min-width="8.33%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partDeep|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partDownSpeed" label="段速度(cm/min)" min-width="8.33%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partDownSpeed|Speedfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partPressure1" label="段压桩力(MPa)" min-width="8.33%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partPressure1|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="angle" label="垂直度(%)" min-width="8.33%" align='center'>
                <template slot-scope="scope">
                  {{scope.row.angle|numfilters}}
                </template>
              </el-table-column>
              <el-table-column property="partTime" label="段时长(s)" min-width="8.33%" align='center'>
              </el-table-column>
              <el-table-column property="partPressure1" label="状态" min-width="8.33%" align='center'>
                <template slot-scope="scope">
                  {{scope.row.partPressure1|statusfilter}}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 1">
          <div class="m-pop-echart">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart02" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-footer class="m-selectAll">
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot, jumper, sizes" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import moment from "moment";
import { historyList } from "@/api/api";
import gTabs from "@/components/gTabs";
import * as echarts from "echarts";
export default {
  components: {
    gTabs
  },
  props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'handleCurrentChange', 'toLastPage', 'toFirstPage', 'pileDescribe'],
  data() {
    return {
      length: '',
      actualLength: '',
      totalChecked: false,
      startDate: '',
      endDate: '',
      number: "",
      deviceKey: '',
      echartsdata: [],
      selectItem: [],
      centerDialogVisible: false,
      tab2: 0,
      tab2List: [
        {
          name: "详细内容",
        },
        {
          name: "图表曲线",
        },
      ],
      selectTable: "记录报表下载",
      selectTableList: [
        {
          label: "记录报表下载",
          value: "0",
        },
        {
          label: "记录报表下载1",
          value: "1",
        },
      ],
      inquire: [],
      totalNum: 0,
      echart: [],
      showButton: false
    }
  },
  filters: {
    statusfilter(val) {
      if (val !== 0) {
        return '压桩'
      } else if (val == 0) {
        return '接桩'
      }
    },
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val, deg) {
      if (deg == undefined) {
        deg = 2
      }
      if (isNaN(val)) {
        return ''
      } else {
        return parseFloat(val).toFixed(deg)
      }
    },
    Speedfilters(val) {
      return Math.abs(val.toFixed(2))
    },
    minfilters(val) {
      return (parseFloat(val) / 60).toFixed(0)
    }
  },
  watch: {
    centerDialogVisible(val, oldval) {
      if (val == false) {
        this.showButton = false
        this.selectItem = []
        this.echartsdata = []
        this.tab2 = 0
        if (this.myChart01 === undefined || this.myChart01._disposed) return
        this.myChart01.dispose()
        this.myChart02.dispose()
      }
    }
  },
  mounted() {
    this.deviceKey = this.device
  },
  methods: {
    // 图表下载
    downLoadImage() {
      let a = document.createElement('a');
      a.href = this.myChart01.getConnectedDataURL({
        pixelRatio: 2,
        backgroundColor: '#fff'
      })
      a.download = "图片下载.png";
      a.click();
    },
    // 查询
    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      historyList('GZ', 0, this.deviceKey, this.pageSize, this.number, this.startDate, this.endDate).then((res) => {
        this.inquire = res.content;
        this.totalNum = res.totalElements;
        this.$emit('changeDevicekey', this.deviceKey, this.number, this.startDate, this.endDate, this.inquire, this.totalNum,)
        loading.close()
      });
      setTimeout(() => {
        loading.close()
      }, 6000)
    },
    // 重置
    resetClick() {
      this.deviceKey = ""
      this.number = ''
      this.startDate = ''
      this.endDate = ''
    },

    initEchart01() {
      let data = this.echartsdata;
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partPressure1.toFixed(3) + ''];
      });
      let option = {
        title: {
          text: "压桩力曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "3%",
          data: ["压桩力"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        // toolbox: {
        //   right: "3%",
        //   top: "3%",
        //   // feature: {
        //   //   saveAsImage: {},
        //   // },
        // },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "压桩力",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "压桩力",
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart01.setOption(option);
      this.echart.push(this.myChart01)
    },
    initEchart02() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep.toFixed(3)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDownSpeed.toFixed(2) + ''];
      });
      let option = {
        title: {
          text: "深度、速度曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "3%",
          data: ["深度", "速度"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        // toolbox: {
        //   right: "3%",
        //   top: "3%",
        //   // feature: {
        //   //   saveAsImage: {},
        //   // },
        // },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度",
            type: "value",
            inverse: true,
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: "速度",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            splitLine: {
              show: false
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "速度",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart02 = echarts.init(this.$refs.myChart02, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart02.setOption(option);
      this.echart.push(this.myChart02)
    },
    // 详情弹出框标签切换
    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index == 0) {
        this.showButton = false
      }
      if (index === 1) {
        this.showButton = true
        this.$nextTick(() => {
          this.initEchart01();
          this.initEchart02();
          echarts.connect(this.echart)
          window.onresize = function () {
            self.myChart01.resize();
            self.myChart02.resize();
          };
        });
      }
    },
    // 详情点击
    handleCommand(index) {
      // 数据暂存在selectItem中
      this.selectItem = this.tableData[index];
      this.print = this.tableData[index].print
      this.centerDialogVisible = true;
      if (this.selectItem.designLength == undefined) {
        this.length = 0
        this.selectItem.designPileAmount = 0
      } else {
        if (this.selectItem.designLength == 0) {
          this.length = 0
        } else {
          if (this.selectItem.designLength3 == 0) {
            this.length = this.selectItem.designLength1 + '+' + this.selectItem.designLength2
          } else if (this.selectItem.designLength4 == 0) {
            this.length = this.selectItem.designLength1 + '+' + this.selectItem.designLength2 + '+' + this.selectItem.designLength3
          } else if (this.selectItem.designLength5 == 0) {
            this.length = this.selectItem.designLength1 + '+' + this.selectItem.designLength2 + '+' + this.selectItem.designLength3 + '+' + this.selectItem.designLength4
          } else if (this.selectItem.designLength2 == 0) {
            this.length = this.selectItem.designLength1
          } else {
            this.length = this.selectItem.designLength1 + '+' + this.selectItem.designLength2 + '+' + this.selectItem.designLength3 + '+' + this.selectItem.designLength4 + '+' + this.selectItem.designLength5
          }
        }
      }

      if (this.selectItem.length1 == 0) {
        this.actualLength = 0
      } else if (this.selectItem.length2 == 0) {
        this.actualLength = this.selectItem.length1.toFixed(2)
      } else if (this.selectItem.length3 == 0) {
        this.actualLength = this.selectItem.length1.toFixed(2) + '+' + this.selectItem.length2.toFixed(2)
      } else if (this.selectItem.length4 == 0) {
        this.actualLength = this.selectItem.length1.toFixed(2) + '+' + this.selectItem.length2.toFixed(2) + '+' + this.selectItem.length3.toFixed(2)
      } else if (this.selectItem.length5 == 0) {
        this.actualLength = this.selectItem.length1.toFixed(2) + '+' + this.selectItem.length2.toFixed(2) + '+' + this.selectItem.length3.toFixed(2) + '+' + this.selectItem.length4.toFixed(2)
      } else {
        this.actualLength = this.selectItem.length1.toFixed(2) + '+' + this.selectItem.length2.toFixed(2) + '+' + this.selectItem.length3.toFixed(2) + '+' + this.selectItem.length4.toFixed(2) + '+' + this.selectItem.length5.toFixed(2)
      }

      var day = this.selectItem.beginTime
      var time
      for (var i = 0; i < this.tableData[index].data.length; i++) {
        if (this.tableData[index].data[i].partTime == 0) {
          this.tableData[index].data[i].partTime = this.tableData[index].data[i].partTime + 1
        }
        day += this.tableData[index].data[i].partTime
        time = moment(day * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.echartsdata.push(time)
      }
      var startTime = this.selectItem.beginTime
      var serialNumber = []
      for (var j = 0; j < this.selectItem.data.length; j++) {
        this.selectItem.data[j].startDay = moment(startTime * 1000).format('YYYY-MM-DD HH:mm:ss')
        startTime += this.selectItem.data[j].partTime
        if (startTime == this.selectItem.data[j]) {
          this.selectItem.data[j].serial = serialNumber.push(j)
        }
      }
    },
    // 选中发生变化
    selectionChange(e) {
      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },
  }
}
</script>
<style scoped>
.el-col-12 {
  width: 100%;
}
.ul-detail li {
  width: 33%;
}
/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.3rem 0 0;
}
.ul-detail li:first-child {
  padding-left: 1.5rem;
}
.ul-detail .s2 .tit {
  min-width: 6em;
}
</style>
