<template>
  <div class="row-history">
    <!-- m-topNav -->
    <div class="m-topNav">
      <el-tabs @tab-click="handleClick" v-model="tab">
        <el-tab-pane v-for="item of tabList" :key="item.name" :label="item.label" :name="item.name">
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- m-topNav end -->
    <!-- row-con -->
    <el-container class="row-con">
      <component @changeDevicekey="changeDevicekey" :is="tab" :pileDescribe="pileDescribe" :tableData="tableData" :device="device" :deviceList="deviceList" :pageSize="pageSize" :total="total" :currentPage="currentPage" :handleSizeChange="handleSizeChange" :handleCurrentChange="handleCurrentChange" :toLastPage="toLastPage" :toFirstPage="toFirstPage"></component>
    </el-container>
    <!-- row-con end -->
  </div>
</template>

<script>
import { queryGroups, queryDevices, historyList } from "@/api/api";
import gTabs from "@/components/gTabs";
import moment from "moment";
import JBZ from "@/views/history/JBZ";
import FPZ from '@/views/history/FPZ'
import GYXPZ from "@/views/history/GYXPZ";
import GH from "@/views/history/GH";
import CGSZ from "@/views/history/CGSZ";
import GFZ from "@/views/history/GFZ";
import CFG from "@/views/history/CFG";
import GJ from "@/views/history/GJ";
import CP from "@/views/history/CP";
import HTZJ from "@/views/history/HTZJ";
import GZ from "@/views/history/GZ";
import DK from "@/views/history/DK";
import XWZ from "@/views/history/XWZ"
import ZKFPZ from "@/views/history/ZKFPZ"
import DP from "@/views/history/DP"
import LG from "@/views/history/LG"
import SP from "@/views/history/SP"
import A_RD from "@/views/history/A_RD"
export default {
  name: "History",
  components: {
    JBZ, GYXPZ, GH, CGSZ, GFZ, CFG, GJ, CP, HTZJ, GZ, gTabs, FPZ, DK, XWZ, ZKFPZ, DP, LG, SP, A_RD
  },
  data() {
    return {
      startday: '',
      endday: '',

      pileDescribe: '',
      deviceList: [],
      device: '',
      tab: "GH",
      pageSize: 10,
      total: 0,
      currentPage: 1,
      tabList: [],
      tableData: [],
      minPileDepth: '',
      mincumulitePileAsh: '',
      minPileTime: '',
      maxPileDepth: '',
      maxCumulitePileAsh: '',
      maxPileTime: '',
      minAverageAsh: '',
      maxAverageAsh: ''
    };
  },
  mounted() {
    queryGroups().then((res) => {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.tabList = res;
      this.tab = res[0].name;

      if (sessionStorage['type'] != undefined) {
        this.tab = sessionStorage['type']
      } else {
        this.tab = this.tabList[0].name;
      }

      if (sessionStorage['deviceKey'] != undefined) {
        this.device = sessionStorage['deviceKey']
      } else {
        this.device = ''
      }
      if (sessionStorage['pileDescribe'] != undefined) {
        this.pileDescribe = sessionStorage['pileDescribe']
        sessionStorage.removeItem('pileDescribe')
      } else {
        this.pileDescribe = ''
      }
      if (this.tab == 'JBZ') {
        if (sessionStorage['pileDepth'] != undefined) {
          this.minPileDepth = sessionStorage['pileDepth']
        } else {
          this.minPileDepth = ''
        }
        if (sessionStorage['cumulativePileAsh'] != undefined) {
          this.mincumulitePileAsh = sessionStorage['cumulativePileAsh']
        } else {
          this.mincumulitePileAsh = ''
        }
        if (sessionStorage['pileTime'] != undefined) {
          this.minPileTime = sessionStorage['pileTime']
        } else {
          this.minPileTime = ''
        }
      }
      queryDevices(9999, 1, this.tab).then((res) => {
        this.deviceList = res.result.records;
        // this.tabList = res
        // this.tab = this.tabList[0].name

        sessionStorage.removeItem('deviceKey')
        historyList(this.tab, 0, this.device, this.pageSize, this.pileDescribe, this.startday, this.endday, this.minPileDepth, this.mincumulitePileAsh, this.minPileTime).then((res) => {
          this.tableData = res.content;
          this.total = res.totalElements;
          this.deviceNames()
          loading.close()
        });
      });

      setTimeout(() => {
        loading.close()
      }, 6000)
    });
    let self = this;
  },
  methods: {
    deviceNames() {
      let map = new Map()
      for (let i = 0; i < this.deviceList.length; i++) {
        map.set(this.deviceList[i].key, this.deviceList[i].name)
      }
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].deviceName = map.get(this.tableData[i].deviceKey)
      }
    },
    // 用来接收子页面传过来的值
    changeDevicekey(devicekey, pileDescribe, startdays, enddays, tabledata, totalNum, pileDepth2, pileAsh, pileAsh2, cumulativeAsh2, pileTime2, pileDepth, pileTime, cumulativeAsh) {
      this.device = devicekey
      this.startday = startdays
      this.endday = enddays
      this.pileDescribe = pileDescribe
      this.currentPage = 0
      this.tableData = tabledata
      this.total = totalNum
      this.maxPileDepth = pileDepth2
      this.minAverageAsh = pileAsh
      this.maxAverageAsh = pileAsh2
      this.maxCumulitePileAsh = cumulativeAsh2
      this.maxPileTime = pileTime2
      this.minPileDepth = pileDepth
      this.mincumulitePileAsh = cumulativeAsh
      this.minPileTime = pileTime

    },
    // 条件查询
    conditionsQuery() {
      if (this.tab != 'JBZ') {
        this.minPileDepth = ''
        this.mincumulitePileAsh = ''
        this.minPileTime = ''
        this.maxPileDepth = ''
        this.minAverageAsh = ''
        this.maxAverageAsh = ''
        this.maxCumulitePileAsh = ''
        this.maxPileTime = ''
      } else {
        this.minPileDepth = sessionStorage['pileDepth']
        this.mincumulitePileAsh = sessionStorage['cumulativePileAsh']
        this.minPileTime = sessionStorage['pileTime']
      }
    },
    toFirstPage() {
      this.handleCurrentChange(1)
    },
    toLastPage() {
      this.handleCurrentChange(Math.ceil(this.total / this.pageSize))
    },
    handleSizeChange(val) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.pageSize = val;
      historyList(this.tab, this.currentPage - 1, this.device, this.pageSize, this.pileDescribe, this.startday, this.endday, this.minPileDepth, this.mincumulitePileAsh, this.minPileTime,
        this.maxPileDepth, this.minAverageAsh, this.maxAverageAsh, this.maxCumulitePileAsh, this.maxPileTime).then((res) => {
          this.tableData = res.content;
          this.total = res.totalElements;
          this.deviceNames()
          loading.close();
        });
      setTimeout(() => {
        loading.close();
      }, 6000);
    },
    handleCurrentChange(val) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.currentPage = val;
      historyList(this.tab, this.currentPage - 1, this.device, this.pageSize, this.pileDescribe, this.startday, this.endday, this.minPileDepth, this.mincumulitePileAsh, this.minPileTime,
        this.maxPileDepth, this.minAverageAsh, this.maxAverageAsh, this.maxCumulitePileAsh, this.maxPileTime).then((res) => {
          this.tableData = res.content;
          this.total = res.totalElements;
          this.deviceNames()
          loading.close();
        });
      setTimeout(() => {
        loading.close();
      }, 6000);
    },
    // 点击切换顶部标签
    handleClick() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.device = ""
      this.pileDescribe = ""
      this.currentPage = 1;
      this.startday = ''
      this.endday = ''
      queryDevices(9999, 1, this.tab).then((res) => {
        this.deviceList = res.result.records;
        sessionStorage['type'] = this.tab
        this.conditionsQuery()
        historyList(this.tab, this.currentPage - 1, "", this.pageSize, '', this.startday, this.endday, this.minPileDepth, this.mincumulitePileAsh, this.minPileTime).then((res) => {
          this.tableData = res.content;
          this.total = res.totalElements;
          this.deviceNames()
          loading.close();
        });
      });
      setTimeout(() => {
        loading.close();
      }, 6000);
    },
  },
};
</script>

<style></style>
