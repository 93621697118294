<template>
  <div class="row-history">
    <component :is="report" :selectItem="selectItem" :ReportDepthSP="ReportDepthSP" :CKtime="CKtime" :CKcurrent="CKcurrent" :CKPressure="CKPressure" :CZtime="CZtime" :CZweight="CZweight" :CZcurrent="CZcurrent" :CZLZtime="CZLZtime" :CZZMtime="CZZMtime" :CZPressure="CZPressure"></component>
    <a id="simulation" href=""></a>
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
                <el-button type="primary" @click="moreConditions()">更多条件</el-button>
                <el-button type="primary" @click="showData()">选择参数</el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item input">
                  <el-input class="input" placeholder="请输入桩号" v-model="pileDescribe"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <!-- 历史列表 -->
      <el-main class="m-table-main">
        <div id="table-content">
          <!-- :cell-style="backgroundColor" -->
          <el-table class="jbThead" :data="tableData" style="width: 100%" ref="table" row-key="id" align='center' @selection-change="selectionChange" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
            <el-table-column type="selection" align='center' fixed></el-table-column>
            <el-table-column :width="columnWidth(index)" v-for="(col,index) in cols" :key="index" :prop="col.prop" :label="col.label" :formatter="col.formatter" min-width='160px' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column fixed="right">
              <template slot-scope="scope">
                <div class="m-edit-btns">
                  <div class="item">
                    <a href="javascript:" class="btn" @click="handleCommand(scope.$index)">详情</a>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-main>
      <!-- 选择参数按钮弹出框 -->
      <el-dialog title="选择需要展示的参数" :visible.sync="dialogFormVisible" width="40%" center top="15vh">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedFileds" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in fieldNames" :label="item.prop" :key="item.prop">{{ item.label }}</el-checkbox>
        </el-checkbox-group>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="medium">取 消</el-button>
          <el-button type="primary" @click="confirmShow" size="medium">确定</el-button>
        </div>
      </el-dialog>
      <!-- 更多条件弹出框 -->
      <el-dialog title="更多条件" width="40%" :visible.sync="dialogFormVisibleMore">
        <div>
          <el-form class="more-filterbox">
            <div class="moreConditions">
              <div class="items">{{"\xa0\xa0\xa0\xa0\xa0"}}施工桩长(m)</div>
              <el-input class="input" v-model="pileDepth"></el-input>
              <div class="item">至</div>
              <el-input class="input" v-model="pileDepth2"></el-input>
            </div>
            <div class="moreConditions">
              <div class="items">平均灰量(kg/m)</div>
              <el-input class="input" v-model="pileAsh"></el-input>
              <div class="item">至</div>
              <el-input class="input" v-model="pileAsh2"></el-input>
            </div>
            <div class="moreConditions">
              <div class="items">{{"\xa0\xa0\xa0\xa0"}}累计灰量(kg)</div>
              <el-input class="input" v-model="cumulativePileAsh"></el-input>
              <div class="item">至</div>
              <el-input class="input" v-model="cumulativePileAsh2"></el-input>
            </div>
            <div class="moreConditions">
              <div class="items">{{"\xa0\xa0\xa0\xa0\xa0"}}成桩时长(s)</div>
              <el-input class="input" v-model="pileTime"></el-input>
              <div class="item">至</div>
              <el-input class="input" v-model="pileTime2"></el-input>
            </div>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleMore = false">取 消</el-button>
          <el-button type="primary" @click="moreDetermine()">确 定</el-button>
        </span>
      </el-dialog>
    </el-container>
    <!-- 详情弹出框 -->
    <el-dialog :visible.sync="centerDialogVisible" class="m-pop m-pop-detail" v-el-dialog-drag>
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
        <div class="right">
          <div v-if="show" class="export_echart" @click="download">图片下载</div>
          <el-dropdown placement="bottom-start" @command="downLoad">
            <span class="more ">报表下载
            </span>
            <el-dropdown-menu class="g-dropdown">
              <el-dropdown-item :command="0">
                <span class="icon icon01"></span>
                <span class="name">导出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <!-- 详细数据 -->
      <div class="dialog-content">
        <div class="m-tab-table m-tab-detail" v-show="tab2 === 0">
          <div class="m-table-detail">
            <!-- 详细数据 -->
            <div class="detailitem">
              <h3>详细数据</h3>
              <div class="box">
                <ul class="ul-detail">
                  <li class="s1">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">桩{{"\xa0\xa0\xa0\xa0\xa0\xa0\xa0"}}号</div>
                        <div class="value valuepile" :title=this.selectItem.pileDescribe>{{selectItem.pileDescribe}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">桩机编号</div>
                        <div class="value">{{selectItem.deviceKey}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">施工时长</div>
                        <div class="value">{{selectItem.pileTime|minfilters}} min</div>
                      </div>
                      <div class="item">
                        <div class="tit">施工桩长</div>
                        <div class="value">{{selectItem.depth|numfilters}} m</div>
                      </div>
                    </div>
                  </li>
                  <li class="s1">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">设计桩长</div>
                        <div class="value">24 m</div>
                      </div>
                      <div class="item">
                        <div class="tit">设计桩径</div>
                        <div class="value">1.2 m</div>
                      </div>
                      <div class="item">
                        <div class="tit">振密电流</div>
                        <div class="value">130 A</div>
                      </div>
                      <div class="item">
                        <div class="tit">留振时长</div>
                        <div class="value">8 s</div>
                      </div>
                    </div>
                  </li>
                  <li class="s1">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">填{{"\xa0\xa0"}}料{{"\xa0\xa0"}}量</div>
                        <div class="value">{{selectItem.totalWeight/1000|numfilters}} t</div>
                      </div>
                      <div class="item">
                        <div class="tit">砂石密度</div>
                        <div class="value">1.50 t/m³</div>
                      </div>
                      <div class="item">
                        <div class="tit">充盈系数</div>
                        <div class="value">1.2</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计水量</div>
                        <div class="value">{{selectItem.totalWater|numfilters}} L</div>
                      </div>
                    </div>
                  </li>
                  <li class="s1">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">开始时间</div>
                        <div class="value">{{selectItem.beginTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">结束时间</div>
                        <div class="value">{{selectItem.endTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">成孔时长</div>
                        <div class="value">{{selectItem.CKPileTime}} min</div>
                      </div>
                      <div class="item">
                        <div class="tit">成桩时长</div>
                        <div class="value">{{selectItem.CZPileTime}} min</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 段数据 -->
            <br />
            <h3>段数据列表</h3>
            <div class="dialogTable">
              <table class="table">
                <thead>
                  <tr>
                    <th colspan="1" rowspan="2">深度<br>m</th>
                    <th colspan="3" style="border-right:10px solid #d8d8d8">成孔</th>
                    <th colspan="5">成桩</th>
                  </tr>
                  <tr class="table_tr">
                    <th>成孔时长(s)</th>
                    <th>成孔电流(A)</th>
                    <th style="border-right:10px solid #d8d8d8">水压(Mpa)</th>
                    <th>成桩时长(s)</th>
                    <th>填料量(t)</th>
                    <th>振密电流(A)</th>
                    <th>留振时间(s)</th>
                    <th>振密时长(s)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="table_tr" v-for="(item,index) in ReportDepthSP" :key="index">
                    <td colspan="1">{{item|numfilters(1)}}</td>
                    <td colspan="1">{{CKtime[index]}}</td>
                    <td colspan="1">{{CKcurrent[index]}}</td>
                    <td colspan="1" style="border-right:10px solid #d8d8d8">{{CKPressure[index]}}</td>
                    <td colspan="1">{{CZtime[index]}}</td>
                    <td colspan="1">{{CZweight[index]/1000|numfilters(1)}}</td>
                    <td colspan="1">{{CZcurrent[index]}}</td>
                    <td colspan="1">8</td>
                    <td colspan="1">{{CZZMtime[index]|filterZM}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <el-table ref="saveTable" class="m-table-save m_table_save_height" :data="selectItem.data" style="width: 100%;margin-top:20px" height='36em'>
              <el-table-column type="index" label="序号" min-width="5%" align="center" width="80px">
              </el-table-column>
              <el-table-column property="partDeep" label="深度(m)" min-width="8.83%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partDeep|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partCurrent" label="电流(A)" min-width="8.83%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partCurrent|numfilters(1) }}
                </template>
              </el-table-column>
              <el-table-column property="partDownSpeed" label="速度(cm/min)" min-width="9.83%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partDownSpeed|num3filters }}
                </template>
              </el-table-column>
              <el-table-column property="totalWater" label="水量(L)" min-width="10.5%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.totalWater|numfilters }}
                </template>
              </el-table-column>

              <el-table-column property="partPressure" label="压力(MPa)" min-width="9.83%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partPressure|numfilters(1) }}
                </template>
              </el-table-column>
              <el-table-column property="totalWeight" label="填料量(kg)" min-width="9.83%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.totalWeight|numfilters(1) }}
                </template>
              </el-table-column>
              <el-table-column property="partStayTime" label="留振时间(s)" min-width="9.83%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partStayTime|numfilters(0) }}
                </template>
              </el-table-column>
              <el-table-column property="partTime" label="段时长(s)" min-width="8.83%" align='center'>
              </el-table-column>
              <el-table-column property="pileStatus" label="状态" min-width="4.66%" align='center'>
              </el-table-column>
            </el-table> -->
          </div>
        </div>
        <!-- 图表曲线 -->
        <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 1">
          <div id="echarts" class="m-pop-echart">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart02" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart03" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart04" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="echatrs" id="echartss">
            <div ref="myChart06" :style="{ width: '100%', height: '33.33%' }"></div>
            <div ref="myChart07" :style="{ width: '100%', height: '33.33%' }"></div>
            <div ref="myChart08" :style="{ width: '100%', height: '33.33%' }"></div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-footer class="m-selectAll">
      <!-- <div class="left">
        <div class="item">
          <el-checkbox v-model="totalChecked" class="total" @change="totalChange">
            全选
          </el-checkbox>
        </div>
        <div class="item">
          <div class="export" id="simulations" href="" @click="exportAll">导出
          </div>
        </div>
      </div> -->
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>

        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot, jumper, sizes" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import report from '../report/report.vue'
import moment from "moment";
import { historyList } from "@/api/api"
import gTabs from "@/components/gTabs";
import * as echarts from "echarts";
const allField = ['pileDescribe', 'deviceKey', 'beginTime', 'endTime', 'designDepth', 'depth', 'totalWeight', 'pileTime', 'downSpeed', 'upSpeed', 'maxDownSpeed', 'maxUpSpeed', 'totalWater',] //列表所有的数据
export default {
  components: {
    gTabs, report
  },
  name: 'carrousel',
  props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'handleCurrentChange', 'toLastPage', 'toFirstPage'],
  data() {
    return {
      // 回放数据
      options: [{
        value: 'ordinary',
        label: '正常'
      }, {
        value: 'fast',
        label: '快速'
      }, {
        value: 'topspeed',
        label: '极速'
      },],
      value: 'ordinary',
      datalength: [],
      totalPartTime: 0,
      f: 0,
      t: 0,
      PlaybackTime: '',
      pileDeep: 0,
      icon: 'el-icon-video-pause',
      // 段数据
      percentage: 0,
      color: '#000',
      playbackTimeDepth: [],
      playbackTimeFlow: [],
      LastTime: 0,
      totalPartPulp: 0,
      totalPartAsh: 0,
      ztTop: 100,//钻头位置
      pileStatusStr: '停钻',
      pileDownStatus: '结束',
      // pulpStatus: '止喷',
      image: 'stop',
      playBack: null,
      dingshi: null,
      y: 0.00,//钻头深度
      dataNum: [],
      recordStatusClass: '',
      pipeStatusClass: '',
      // nozzleStatusClass: '',
      map: null,
      // 回放数据
      pileDepth: '',
      pileDepth2: '',
      pileAsh: '',
      pileAsh2: '',
      pileTime: '',
      pileTime2: '',
      cumulativePileAsh: '',
      cumulativePileAsh2: '',
      // 更多条件按钮参数
      report: 'report',
      checkedData: [],
      totalChecked: false,
      startDate: '',
      endDate: '',
      pileDescribe: '',
      deviceKey: '',
      checkedId: [], //存储点击后导出的id
      checkAll: false,  //默认全选
      checkedFileds: ['pileDescribe', 'deviceKey', 'beginTime', 'endTime', 'designDepth', 'depth', 'totalWeight', 'downSpeed', 'upSpeed', 'pileTime',], //默认显示选中的数据
      fieldNames: [{
        prop: 'pileDescribe',
        label: '桩号'
      },
      {
        prop: 'deviceKey',
        label: '设备编号'
      },
      {
        prop: 'beginTime',
        label: '开始时间',
        formatter: this.testFormatter,
      },
      {
        prop: 'endTime',
        label: '结束时间',
        formatter: this.testFormatter,
      },
      {
        prop: 'designDepth',
        label: '设计桩长',
        formatter: this.designnumFormatter
      },
      {
        prop: 'depth',
        label: '施工桩长(m)',
        formatter: this.numFormatter
      },
      {
        prop: 'totalWeight',
        label: '累计填料量(kg)',
        formatter: this.numsFormatter
      },
      // {
      //   prop: 'pressure',
      //   label: '水/气压(Mpa)',
      //   formatter: this.numsFormatter
      // },
      // {
      //   prop: 'stayTime',
      //   label: '留振时间(s)',
      //   formatter: this.dataFormatter
      // },
      {
        prop: 'pileTime',
        label: '成桩时间(min)	',
        formatter: this.minFormatter
      },
      {
        prop: 'downSpeed',
        label: '下钻速度(cm/min)',
        formatter: this.numsFormatter
      },
      {
        prop: 'upSpeed',
        label: '提钻速度(cm/min)',
        formatter: this.numsFormatter
      },
      {
        prop: 'maxDownSpeed',
        label: '最大钻速(cm/min)',
        formatter: this.numsFormatter
      },
      {
        prop: 'maxUpSpeed',
        label: '最大提速(cm/min)',
        formatter: this.numsFormatter
      },
      {
        prop: 'totalWater',
        label: '累计水量(L)',
        formatter: this.numFormatter
      },

      ],   //列表添加全部数据
      isIndeterminate: false,   //默认全选
      dialogFormVisible: false,
      dialogFormVisibleMore: false, //更多条件弹框
      cols: [
        {
          prop: 'pileDescribe',
          label: '桩号'
        },
        {
          prop: 'deviceKey',
          label: '设备编号'
        },
        {
          prop: 'beginTime',
          label: '开始时间',
          formatter: this.testFormatter,
        },
        {
          prop: 'endTime',
          label: '结束时间',
          formatter: this.testFormatter,
        },
        {
          prop: 'designDepth',
          label: '设计桩长',
          formatter: this.designnumFormatter
        },
        {
          prop: 'depth',
          label: '施工桩长(m)',
          formatter: this.numFormatter
        },
        {
          prop: 'totalWeight',
          label: '累计填料量(kg)',
          formatter: this.numsFormatter
        },
        // {
        //   prop: 'pressure',
        //   label: '水/气压(Mpa)',
        //   formatter: this.numsFormatter
        // },
        // {
        //   prop: 'stayTime',
        //   label: '留振时间(s)',
        //   formatter: this.dataFormatter
        // },
        {
          prop: 'pileTime',
          label: '成桩时间(min)',
          formatter: this.minFormatter
        },
        {
          prop: 'downSpeed',
          label: '下钻速度(cm/min)',
          formatter: this.numsFormatter
        },
        {
          prop: 'upSpeed',
          label: '提钻速度(cm/min)',
          formatter: this.numsFormatter
        },

      ],
      tempCols: [],  //临时的数据容器
      centerDialogVisible: false,// 弹窗
      data: [],
      selectItem: [],
      tab2: 0,
      tab2List: [
        {
          name: "详细内容",
        },
        {
          name: "图表曲线",
        }
      ],
      firstDowntime: [],
      firstDowntime2: [],
      inquire: [],
      totalNum: 0,
      echartAll: [],
      echartsAll: [],
      show: false,
      // 导出报表数据
      ReportDepthSP: [],
      CKtime: [],
      CKcurrent: [],
      CKPressure: [],
      CZtime: [],
      CZweight: [],
      CZcurrent: [],
      CZLZtime: [],
      CZZMtime: [],
      CZPressure: [],
    }
  },
  filters: {
    filterZM(val) {
      if (val == 0) {
        return val
      } else {
        return val = val + ((val + 1) % 4)
      }
    },
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    minfilters(val) {
      return (parseFloat(val) / 60).toFixed(0)
    },
    numfilters(val, deg) {
      if (deg == undefined) {
        deg = 2
      }
      if (isNaN(val)) {
        //val 不是数字
        return val
      } else {
        //val是数字
        return parseFloat(val).toFixed(deg)
      }
    },
    num3filters(val) {
      // return Math.abs(parseFloat(val).toFixed(2))
      return parseFloat(val).toFixed(2)
    },
    num5filters(val) {
      return val < 0 ? '密实' : '造孔'
    }
  },
  watch: {
    centerDialogVisible(val, oldval) {
      window.onresize = null
      if (val == false) {
        this.show = false
        clearInterval(this.playBack)
        this.ReportDepthSP = []
        this.CKtime = []
        this.CKcurrent = []
        this.CKPressure = []
        this.CZtime = []
        this.CZweight = []
        this.CZcurrent = []
        this.CZLZtime = []
        this.CZZMtime = []
        this.CZPressure = []

        this.tab2 = 0
        this.selectItem = []
        this.data = []
        if (this.myChart01 === undefined || this.myChart01._disposed) return
        this.myChart01.dispose()
        this.myChart02.dispose()
        this.myChart03.dispose()
        this.myChart04.dispose()
        this.myChart06.dispose()
        this.myChart07.dispose()
        this.myChart08.dispose()
      }
    }
  },
  mounted() {
    sessionStorage['pileDepth'] = this.pileDepth
    sessionStorage['cumulativePileAsh'] = this.cumulativePileAsh
    sessionStorage['pileTime'] = this.pileTime
    this.deviceKey = this.device

  },
  methods: {
    // 图片下载
    download() {
      let a = document.createElement('a');
      a.href = this.myChart06.getConnectedDataURL({
        pixelRatio: 2,
        backgroundColor: '#fff'
      })
      a.download = "图片下载.png";
      a.click();
    },
    // 设置table表格的宽度 
    columnWidth(index) {
      if (index == 4 || index == 5 || index == 6) {
        return 120
      }
      if (index == 10 || index == 11 || index == 8 || index == 9) {
        return 150
      }
    },
    // 报表下载
    downLoad(command) {
      switch (command) {
        case 0: {
          // window.open(http + "/downloadExcel2Print?id=" + this.selectItem._id)
          this.tableToExcel('SPReports', '振冲砂桩')
          break
        }
      }
    },
    //表格导出的封装
    tableToExcel(tableid, sheetName) {
      let uri = 'data:application/vnd.ms-excel;base64,';
      let template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"' +
        'xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>'
        + '<x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets>'
        + '</x:ExcelWorkbook></xml><![endif]-->' +
        ' <style type="text/css">' +
        'table td {' +
        'height: 25px;' +
        'font-family: 宋体;' +
        'font-size: 13px;' +
        'text-align: left;' +
        'border: 0.5px solid #000000;' +
        ' }' +
        'table .tHead {' +
        'font-size: 20px;' +
        'text-align:center;' +
        'font-weight: bold;' +
        'height: 40px;' +
        ' }' +
        'table tfoot tr{' +
        'height: 25px;' +
        ' }' +
        'table tbody td{' +
        'text-align: center;' +
        'height: 20px;' +
        'mso-number-format:"@";' +
        ' }' +
        '</style>' +
        '</head><body ><table class="excelTable">{table}</table></body></html>';
      if (!tableid.nodeType) tableid = document.getElementById(tableid);
      let ctx;
      if (tableid) {
        ctx = { worksheet: sheetName || 'Worksheet', table: tableid.innerHTML };
        document.getElementById('simulation').href = uri + this.base64(this.format(template, ctx));
        document.getElementById('simulation').download = sheetName + ".xls";//自定义文件名
        document.getElementById('simulation').click();
      }
    },
    base64(s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format(s, c) {
      return s.replace(/{(\w+)}/g,
        function (m, p) {
          return c[p];
        });
    },

    // 设备查询
    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //选择设备查询
      //将修改的key值传到主页面
      //在主页面改为传过来的值
      //主页写接受参数的方法
      historyList('SP', 0, this.deviceKey, this.pageSize, this.pileDescribe, this.startDate, this.endDate).then((res) => {
        // this.tableData = res.content;
        // this.total = res.totalElements;
        this.inquire = res.content;
        this.totalNum = res.totalElements;
        this.$emit('changeDevicekey', this.deviceKey, this.pileDescribe, this.startDate, this.endDate, this.inquire, this.totalNum)
        loading.close();
      });
      setTimeout(() => {
        loading.close();
      }, 6000);
    },
    // 重置按钮
    resetClick() {
      this.deviceKey = ""
      this.pileDescribe = ''
      this.startDate = ''
      this.endDate = ''
    },
    testFormatter(row, column, cellValue, index) {
      return moment(cellValue * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    minFormatter(row, column, cellValue, index) {
      return (parseFloat(cellValue) / 60).toFixed(0)
    },
    numFormatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(2)
    },
    numsFormatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(1)
    },
    dataFormatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(0)
    },
    designnumFormatter(row, column, cellValue, index) {
      if (cellValue == undefined) {
        return '--'
      } else {
        return parseFloat(cellValue).toFixed(2)
      }
    },
    //全选按钮 选择参数显示数据
    handleCheckAllChange(val) {
      this.checkedFileds = val ? allField : [];
      this.isIndeterminate = false;
    },
    //单个选中
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fieldNames.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fieldNames.length;
    },
    // 选择参数弹出框
    showData(row) {
      this.dialogFormVisible = true;
    },
    // 更多条件弹出框
    moreConditions() {
      this.dialogFormVisibleMore = true
    },
    // 更多条件确定按钮
    moreDetermine() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      historyList('SP', 0, this.device, this.pageSize, this.pileDescribe, this.startDate, this.endDate, this.pileDepth, this.cumulativePileAsh, this.pileTime,
        this.pileDepth2, this.pileAsh, this.pileAsh2, this.cumulativePileAsh2, this.pileTime2).then((res) => {
          this.inquire = res.content;
          this.totalNum = res.totalElements;
          this.$emit('changeDevicekey', this.device, this.pileDescribe, this.startDate, this.endDate, this.inquire, this.totalNum,
            this.pileDepth2, this.pileAsh, this.pileAsh2, this.cumulativePileAsh2, this.pileTime2, this.pileDepth, this.pileTime, this.cumulativePileAsh)
          loading.close();
        });
      this.dialogFormVisibleMore = false
    },
    confirmShow() {
      this.tempCols = [];
      if (this.checkedFileds.length < 1) {
        this.$message.warning("请至少选择一项数据");
        this.dialogFormVisible = true;
      } else {
        for (var i = 0; i < this.fieldNames.length; i++) {
          for (var j = 0; j < this.checkedFileds.length; j++) {
            if (this.fieldNames[i].prop == this.checkedFileds[j]) {
              this.tempCols.push(this.fieldNames[i]);
            }
          }
        }
        this.cols = this.tempCols;
        this.dialogFormVisible = false;
      }
    },
    initechart06() {
      let data = this.data;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep.toFixed(2)];
      });
      let option = {
        title: {
          text: "深度曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度"],
        },
        grid: {
          top: "23%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {
            },
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度(m)       ",
            type: "value",
            inverse: true, //设置坐标轴翻转
            nameLocation: 'end',
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          }
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart06 = echarts.init(this.$refs.myChart06, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart06.setOption(option);

      this.echartsAll.push(this.myChart06)
    },
    initechart07() {
      let data = this.data;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partCurrent).toFixed(2)];
      });
      let option = {
        title: {
          text: "电流曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["电流"],
        },
        grid: {
          top: "23%",
          left: "3%",
          right: "5%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {
            },
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "电流(A)",
            type: "value",
            nameLocation: 'start',
            axisLine: { //设置坐标轴是否显示
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            min: 0
          }
        ],
        series: [
          {
            name: "电流",
            type: "line",
            markLine: {
              symbol: 'none',// 不显示箭头和圆点
              data: [{ yAxis: 130, }]
            },
            showSymbol: false,
            data: y1Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart07 = echarts.init(this.$refs.myChart07, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart07.setOption(option);

      this.echartsAll.push(this.myChart07)
    },
    initechart08() {
      let data = this.data;
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.totalWeight.toFixed(3) + ''];
      });
      let option = {
        title: {
          text: "填料量曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["填料量"],
        },
        grid: {
          top: "23%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {
              type: 'png',
            },
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "填料量(kg)",
            type: "value",
            inverse: false,
            nameLocation: "start",
            axisLine: { //设置轴线
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          }
        ],
        series: [
          {
            name: "填料量",
            type: "line",
            showSymbol: false,
            data: y2Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      // this.myChart03 = echarts.init(this.$refs.myChart03, null, { renderer: 'svg' });
      this.myChart08 = echarts.init(this.$refs.myChart08, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart08.setOption(option);

      this.echartsAll.push(this.myChart08)
    },
    initEchart01() {
      let data = this.data;
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.totalWeight.toFixed(3) + ''];
      });
      let option = {
        title: {
          text: "填料量曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["填料量"],
        },
        grid: {
          top: "23%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {
              type: 'png',
            },
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "填料量(kg)",
            type: "value",
            inverse: false,
            nameLocation: "start",
            axisLine: { //设置轴线
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          }
        ],
        series: [
          {
            name: "填料量",
            type: "line",
            showSymbol: false,
            data: y2Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart01.setOption(option);

      this.echartAll.push(this.myChart01)
    },
    initEchart02() {
      let data = this.data;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep.toFixed(2)];
      });
      let option = {
        title: {
          text: "深度曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度"],
        },
        grid: {
          top: "23%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {
            },
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度(m)       ",
            type: "value",
            inverse: true, //设置坐标轴翻转
            nameLocation: 'end',
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          }
        ],
        dataZoom: [
          // 区域缩放
          {
            type: 'inside',
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
          }
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart02 = echarts.init(this.$refs.myChart02, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart02.setOption(option);

      this.echartAll.push(this.myChart02)
    },
    initEchart03() {
      let data = this.data;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partCurrent).toFixed(2)];
      });
      let option = {
        title: {
          text: "电流曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["电流"],
        },
        grid: {
          top: "23%",
          left: "3%",
          right: "5%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {
            },
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "电流(A)",
            type: "value",
            nameLocation: 'start',
            axisLine: { //设置坐标轴是否显示
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            min: 0
          }
        ],
        series: [
          {
            name: "电流",
            type: "line",
            markLine: {
              symbol: 'none',// 不显示箭头和圆点
              data: [{ yAxis: 130, }]
            },
            showSymbol: false,
            data: y1Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      // this.myChart03 = echarts.init(this.$refs.myChart03, null, { renderer: 'svg' });
      this.myChart03 = echarts.init(this.$refs.myChart03, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart03.setOption(option);

      this.echartAll.push(this.myChart03)
    },
    initEchart04() {
      var ash = []
      for (var k = 1; k <= Math.ceil(this.selectItem.depth); k++) {
        ash.push(1 + Math.random() / 20)
      }
      var diameter = (2 * Math.sqrt(this.selectItem.totalWeight.toString() / 1500.00 * 0.9 / 3.14 / this.selectItem.depth)).toFixed(2)
      let option = {
        title: {
          text: "成桩效果图(理论桩径：" + 1.2 + "m)",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} : {c}",
          show: false
        },
        grid: {
          top: "10%",
          bottom: "10%",
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        series: [
          {
            name: "",
            type: "funnel",
            width: "50%",
            left: "center",
            top: "15%",
            bottom: "8%",
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderWidth: 0,
              borderColor: '#5470c6',
              color: '#5470c6'
            },
            data: ash,
            sort: "none", //设置不排序
            color: '#5470c6',
            gap: 0  //设置两个块之间的距离
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart04 = echarts.init(this.$refs.myChart04, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart04.setOption(option);

      // var ash = []
      // for (var j = 0; j < this.selectItem.data.length; j++) {
      //   if (ash[Math.ceil(this.selectItem.data[j].partDeep - 1)] == undefined) {
      //     ash[Math.ceil(this.selectItem.data[j].partDeep - 1)] = 0
      //   }
      //   ash[Math.ceil(this.selectItem.data[j].partDeep - 1)] += this.selectItem.data[j].totalWater
      // }
      // var numbers2 = ash.map(myFunction);
      // function myFunction(value, index, array) {
      //   var data = {
      //     value: Number(value.toFixed(2)),
      //     name: index + 1
      //   }
      //   return data;
      // }
      // let option = {
      //   title: {
      //     text: "成桩水量分布",
      //     textStyle: {
      //       color: "#101D34",
      //       fontSize: 16,
      //     },
      //     left: "2.2%",
      //     top: "5%",
      //   },
      //   tooltip: {
      //     trigger: "item",
      //     formatter: "{a} : {c}L",
      //   },
      //   grid: {
      //     top: "10%",
      //     bottom: "10%",
      //   },
      //   toolbox: {
      //     feature: {
      //       saveAsImage: {},
      //     },
      //   },
      //   series: [
      //     {
      //       name: "水量分布",
      //       type: "funnel",
      //       width: "50%",
      //       left: "center",
      //       top: "15%",
      //       bottom: "8%",
      //       label: {
      //         show: true,
      //       },
      //       labelLine: {
      //         show: true,
      //       },
      //       data: numbers2,
      //       sort: "none",
      //       color: '#5470c6'
      //     },
      //   ],
      // };
      // // 基于准备好的dom，初始化echarts实例
      // this.myChart04 = echarts.init(this.$refs.myChart04, null, { renderer: 'svg' });
      // // 绘制图表
      // this.myChart04.setOption(option);
    },
    initEchart05() {
      let option = {
        title: {
          text: "深度、流量曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "流量"],
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度(m)  ",
            type: "value",
            inverse: true,
            nameLocation: "end",
            axisLine: { //设置轴线
              show: true
            },
            max: 30,
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: " 流量(L/min)",
            nameLocation: "start", // 设置坐标轴名称显示位置
            // inverse: true,
            type: "value",
            splitLine: { //网格不显示
              show: false
            },
            max: 120,
            min: 0,
            nameTextStyle: {
              padding: [10, 20, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: this.playbackTimeDepth,
          },
          {
            name: "流量",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: this.playbackTimeFlow,
          },
        ],
      };

      // 绘制图表
      this.myChart05.setOption(option);
    },
    // 倾角
    // setAngle(xAngle, yAngle) {
    //   let angleOption = {
    //     tooltip: {
    //       show: false,
    //       trigger: "axis",
    //       formatter: 'x: ' + xAngle + '<br />y :' + yAngle
    //     },
    //     grid: {
    //       left: "0",
    //       top: "0",
    //       right: "0",
    //       bottom: "0"
    //     },
    //     xAxis: {
    //       type: "value",
    //       min: "-20",
    //       max: "20",
    //       axisLabel: {
    //         show: false
    //       }
    //     },
    //     yAxis: {
    //       type: "value",
    //       min: "-20",
    //       max: "20",
    //       axisLabel: {
    //         show: false
    //       }
    //     },
    //     series: [{
    //       symbolSize: 10,
    //       data: [
    //         [xAngle, yAngle]
    //       ],
    //       type: 'scatter'
    //     }]
    //   };
    //   if (this.angle == undefined) {
    //     this.angle = echarts.init(this.$refs.angle);
    //   }
    //   this.angle.setOption(angleOption);
    // },
    // 历史详情弹出框头部标签点击切换
    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index === 0) {
        this.show = false
      }
      if (index === 1) {
        this.show = true
        this.$nextTick(() => {
          this.initechart06()
          this.initechart07()
          this.initechart08()
          echarts.connect(this.echartsAll)
          this.initEchart04();
          this.initEchart01(), this.initEchart02(), this.initEchart03()
          // 图表实例实现联动
          echarts.connect(this.echartAll)
          window.onresize = function () {
            self.myChart01.resize();
            self.myChart02.resize();
            self.myChart03.resize();
            self.myChart04.resize();
          };
        });
      }
    },
    // 点击详情 详情弹出框
    handleCommand(index) {
      this.selectItem = this.tableData[index]
      // 显示弹出框
      this.centerDialogVisible = true;
      var day = this.selectItem.beginTime
      var time
      let data = []
      for (var i = 0; i < this.tableData[index].data.length; i++) {
        day += this.tableData[index].data[i].partTime
        time = moment(day * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.data.push(time)
        data.push(this.tableData[index].data[i].partDeep)
      }
      var a = 0
      let depthReport = [] //深度
      for (let i = 0; i < data.length; i++) {
        if (data[i] == Math.max(...data)) {
          a = i
        }
        depthReport.push((Math.ceil(data[i] * 2)) / 2)
      }
      depthReport = [...new Set(depthReport)]
      depthReport.sort((old, New) => {
        return old - New
      }) //数组排序
      if (depthReport[0] == 0) {
        depthReport.splice(0, 1)
      }
      this.ReportDepthSP = depthReport
      let chengkong = []
      let chengzhuang = []
      for (let i = 0; i < this.selectItem.data.length; i++) {
        if (data[i] <= Math.max(...data) && i <= a) {
          this.selectItem.data[i].pileStatus = '造孔'
          chengkong.push(this.selectItem.data[i])
        } else if (this.selectItem.data[i].partDownSpeed >= 0 && i > a) {
          this.selectItem.data[i].pileStatus = '密实'
          chengzhuang.push(this.selectItem.data[i])
        } else if (this.selectItem.data[i].partDownSpeed < 0 && i > a) {
          this.selectItem.data[i].pileStatus = '提升'
          chengzhuang.push(this.selectItem.data[i])
        }
      }
      // 声明汇总chengkong map
      // 循环chengkong
      // 成孔数据
      let map = new Map()
      let CKPartTime = 0
      for (let i = 0; i < chengkong.length; i++) {
        CKPartTime += chengkong[i].partTime
        if (map.get((Math.ceil(chengkong[i].partDeep * 2)) / 2) == undefined) {
          let time = 0
          let deeep = chengkong[i].partCurrent
          let water = chengkong[i].partPressure
          time = chengkong[i].partTime
          map.set((Math.ceil(chengkong[i].partDeep * 2)) / 2, [deeep, water, time])
        } else {
          let waterPressures
          let currentWater
          let deeep = map.get((Math.ceil(chengkong[i].partDeep * 2)) / 2)[0]
          let waters = map.get((Math.ceil(chengkong[i].partDeep * 2)) / 2)[1]
          let time = map.get((Math.ceil(chengkong[i].partDeep * 2)) / 2)[2]
          time += chengkong[i].partTime
          if (waters > chengkong[i].partPressure || waters == chengkong[i].partPressure) {
            waterPressures = waters
          } else {
            waterPressures = chengkong[i].partPressure
          }
          if (deeep > chengkong[i].partCurrent || deeep == chengkong[i].partCurrent) {
            currentWater = deeep
          } else {
            currentWater = chengkong[i].partCurrent
          }
          map.set((Math.ceil(chengkong[i].partDeep * 2)) / 2, [currentWater, waterPressures, time])
        }
      }
      // 成桩数据
      let CZmap = new Map()
      let CZPartTime = 0
      for (let i = 0; i < chengzhuang.length; i++) {
        CZPartTime += chengzhuang[i].partTime
        if (CZmap.get((Math.ceil(chengzhuang[i].partDeep * 2)) / 2) == undefined) {
          let time = 0
          let LZtime = 0
          let ZMtime = 0
          let deeep = chengzhuang[i].partCurrent
          let water = chengzhuang[i].partPressure
          let weight = chengzhuang[i].totalWeight
          time = chengzhuang[i].partTime
          // LZtime = chengzhuang[i].partTime
          // ZMtime = chengzhuang[i].partTime
          CZmap.set((Math.ceil(chengzhuang[i].partDeep * 2)) / 2, [deeep, water, time, weight, LZtime, ZMtime])
        } else {
          let CZDL
          let CZYL
          let czCurrent = CZmap.get((Math.ceil(chengzhuang[i].partDeep * 2)) / 2)[0]
          let water = CZmap.get((Math.ceil(chengzhuang[i].partDeep * 2)) / 2)[1]
          let time = CZmap.get((Math.ceil(chengzhuang[i].partDeep * 2)) / 2)[2]
          time += chengzhuang[i].partTime
          let weight = Number(CZmap.get((Math.ceil(chengzhuang[i].partDeep * 2)) / 2)[3])
          weight += Number(chengzhuang[i].totalWeight.toFixed(2))
          let LZtime = CZmap.get((Math.ceil(chengzhuang[i].partDeep * 2)) / 2)[4]
          let ZMtime = CZmap.get((Math.ceil(chengzhuang[i].partDeep * 2)) / 2)[5]
          if (chengzhuang[i].partCurrent > 130) {
            ZMtime += chengzhuang[i].partTime
          }
          if (Math.abs(chengzhuang[i].partDownSpeed) < 120 && chengzhuang[i].partCurrent > 120) {
            LZtime += chengzhuang[i].partTime
          }
          if (czCurrent > chengzhuang[i].partCurrent || czCurrent == chengzhuang[i].partCurrent) {
            CZDL = czCurrent
          } else {
            CZDL = chengzhuang[i].partCurrent
          }
          if (water > chengzhuang[i].partPressure || water == chengzhuang[i].partPressure) {
            CZYL = water
          } else {
            CZYL = chengzhuang[i].partPressure
          }
          CZmap.set((Math.ceil(chengzhuang[i].partDeep * 2)) / 2, [CZDL, CZYL, time, weight, LZtime, ZMtime])
        }
      }
      if (map.get(0) != undefined && map.get(0.5) != undefined) {
        if (map.get(0)[0] >= map.get(0.5)[0]) {
          map.get(0.5)[0] = map.get(0)[0]
        } else {
          map.get(0.5)[0] = map.get(0.5)[0]
        }
        if (map.get(0)[1] >= map.get(0.5)[1]) {
          map.get(0.5)[1] = map.get(0)[1]
        } else {
          map.get(0.5)[1] = map.get(0.5)[1]
        }
        map.get(0.5)[2] = map.get(0)[2] + map.get(0.5)[2]
      } else if (map.get(0) != undefined && map.get(0.5) == undefined) {
        map.set(0.5, [map.get(0)[0], map.get(0)[1], map.get(0)[2]])
      }
      if (CZmap.get(0) != undefined && CZmap.get(0.5) != undefined) {
        if (CZmap.get(0)[0] >= CZmap.get(0.5)[0]) {
          CZmap.get(0.5)[0] = CZmap.get(0)[0]
        } else {
          CZmap.get(0.5)[0] = CZmap.get(0.5)[0]
        }
        if (CZmap.get(0)[1] >= CZmap.get(0.5)[1]) {
          CZmap.get(0.5)[1] = CZmap.get(0)[1]
        } else {
          CZmap.get(0.5)[1] = CZmap.get(0.5)[1]
        }
        CZmap.get(0.5)[2] = CZmap.get(0)[2] + CZmap.get(0.5)[2]
        CZmap.get(0.5)[3] = CZmap.get(0)[3] + CZmap.get(0.5)[3]
        CZmap.get(0.5)[4] = CZmap.get(0)[4] + CZmap.get(0.5)[4]
        CZmap.get(0.5)[5] = CZmap.get(0)[5] + CZmap.get(0.5)[5]
      } else if (CZmap.get(0) != undefined && CZmap.get(0.5) == undefined) {
        CZmap.set(0.5, [CZmap.get(0)[0], CZmap.get(0)[1], CZmap.get(0)[2], CZmap.get(0)[3], CZmap.get(0)[4], CZmap.get(0)[5]])
      }
      for (let i = 0; i < this.ReportDepthSP.length; i++) {
        if (map.get(this.ReportDepthSP[i]) == undefined) {
          // continue
          this.CKtime.push(0)
          this.CKcurrent.push(0)
          this.CKPressure.push(0)
        } else {
          this.CKcurrent.push(map.get(this.ReportDepthSP[i])[0])
          this.CKPressure.push(map.get(this.ReportDepthSP[i])[1])
          this.CKtime.push(map.get(this.ReportDepthSP[i])[2])
        }
        if (CZmap.get(this.ReportDepthSP[i]) == undefined) {
          this.CZtime.push(0)
          this.CZweight.push(0)
          this.CZcurrent.push(0)
          this.CZLZtime.push(0)
          this.CZZMtime.push(0)
          this.CZPressure.push(0)
          // continue
        } else {
          this.CZtime.push(CZmap.get(this.ReportDepthSP[i])[2])
          this.CZweight.push(CZmap.get(this.ReportDepthSP[i])[3])
          this.CZcurrent.push(CZmap.get(this.ReportDepthSP[i])[0])
          this.CZLZtime.push(CZmap.get(this.ReportDepthSP[i])[4])
          this.CZZMtime.push(CZmap.get(this.ReportDepthSP[i])[5])
          this.CZPressure.push(CZmap.get(this.ReportDepthSP[i])[1])
        }
      }
      this.selectItem.CKPileTime = (CKPartTime / 60).toFixed(0)
      this.selectItem.CZPileTime = (CZPartTime / 60).toFixed(0)
    },
    // 历史页面全选选择框 全选列表导出
    selectionChange(e) {
      this.checkedData = e.reverse()
      this.checkedId = []
      for (var i = 0; i < e.length; i++) {
        this.checkedId.push(e[i]._id)
      }
      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },
    // 钻头
    myFun(y) {
      var that = this;
      that.y = Number(y).toFixed(2);
      that.ztTop = (100 - y / this.selectItem.depth.toFixed(2) * 100) + '%';
    },
    // 天地图控件
    loadmap() {
      var T = window.T;
      this.map = new T.Map("mapDiv");
      //设置显示地图的中心点和级别
      this.map.centerAndZoom(new T.LngLat(this.selectItem.longitude, this.selectItem.latitude), 10);
      const ctrl = new T.Control.MapType([{
        title: '地图',
        icon: 'http://api.tianditu.gov.cn/v4.0/image/map/maptype/vector.png', //地图控件上所要显示的图层图标（默认图标大小80x80）
        layer: window.TMAP_NORMAL_MAP
      }, {
        title: '卫星',
        icon: 'http://api.tianditu.gov.cn/v4.0/image/map/maptype/satellite.png',
        layer: window.TMAP_SATELLITE_MAP
      }]);
      // 6.将控件添加到地图，一个控件实例只能向地图中添加一次。
      this.map.addControl(ctrl);
      // 创建坐标
      const point = new T.LngLat(this.selectItem.longitude, this.selectItem.latitude);
      // 8.创建覆盖使用的图标
      const icon = new T.Icon({
        iconUrl: "@/assets/images/map/b4.png",
      });
      // 9. 创建在该坐标上的一个图像标注实例
      const marker = new T.Marker(point, icon);
      // 10.将覆盖物添加到地图中，一个覆盖物实例只能向地图中添加一次
      this.map.addOverLay(marker);
      //允许鼠标双击放大地图
      this.map.enableScrollWheelZoom();
    },
  }
}
</script>
<style scoped>
.dialogTable {
  /* padding: 20px 0; */
  background: #fff;
  width: 100%;
  height: 490px;
  overflow-y: auto;
}
.table {
  width: 100%;
  border-radius: 0.625rem;
}
table tr td,
table tr th {
  text-align: center;
  border: 1px solid #d8d8d8;
  color: #101d34;
  font-size: 1rem;
  font-weight: normal;
}
/* thead {
  position: sticky;
  top: -1px;
  z-index: 10;
  background: #fff;
} */
/* .table_tr td,
.table_tr th {
  width: 11.1%;
} */
.export_echart {
  cursor: pointer;
  font-size: 0.75rem;
  background: #409eff;
  text-align: center;
  width: 80px;
  height: 32px;
  line-height: 32px;
  margin-top: 14px;
  border-radius: 4px;
  color: #fff;
  float: left;
}
.m-tab-echartTable {
  position: relative;
}
.echatrs {
  position: absolute;
  top: 0;
  left: -2000px;
  width: 800px;
  height: 660px;
}

/* 历史回放 */
.backplayBottom {
  margin-top: 5px;
  background: #fff;
  height: 3rem;
  display: flex;
  justify-content: space-between;
}
/* >>> .el-input--small .el-input__inner {
  color: #211b1b;
} */
.iconBack {
  color: #211b1b;
  /* color: #409eff; */
  font-size: 28px;
  font-weight: bold;
  line-height: 3.2rem;
}
.progress_bar {
  width: 74%;
  margin-top: 20px;
}
.selectspeed {
  width: 120px;
  margin-top: 10px;
}

.backEchart {
  background: #fff;
  height: 20rem;
  margin: 5px 0 5px 5px;
  border-radius: 10px;
}
.m-statusinfo {
  background: #fff;
  box-shadow: none;
}
.m-datab1 .top {
  margin-bottom: 0px;
}
.picbox .data {
  bottom: 97%;
}
.picbox .databar {
  bottom: 7px;
}
.m-datab1 {
  background: #fff;
  border-radius: 10px;
  margin-left: 5px;
  padding-top: 0.8rem;
}
.mapDiv {
  width: 100%;
  height: 100%;
}
/* 历史回放end */
/* 更多条件 */
.moreConditions {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-bottom: 20px;
}
.moreConditions .item,
.moreConditions .items {
  line-height: 40px;
}
.moreConditions .input {
  width: 38%;
}
/* 更多条件 */
.el-button--primary {
  margin-left: 25px;
  margin-right: 5px;
}
.el-checkbox {
  width: 20%;
}
.more {
  margin-left: 1.75rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAYAAAB836/YAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MUQ5MUJCNkEzRkYxMUVCQTE1NUVDNDc5MDNFOTc4OSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MUQ5MUJCN0EzRkYxMUVCQTE1NUVDNDc5MDNFOTc4OSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjgxRDkxQkI0QTNGRjExRUJBMTU1RUM0NzkwM0U5Nzg5IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjgxRDkxQkI1QTNGRjExRUJBMTU1RUM0NzkwM0U5Nzg5Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+B0oTKQAAAE9JREFUeNpi/P//PwM1ARMDlcGogUPUQE8gfgbFnoQUMxKRDkEGSULZz4FYippeZqSGl1OA+AUQPwXiZGp4eTQdjibs0YQ9mg5HgoEAAQYAMKsdCdjHQ/QAAAAASUVORK5CYII=)
    center center no-repeat;
  background-size: contain;
  float: left;
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  overflow: hidden;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  cursor: pointer;
}

.jbThead {
  font-size: 14px;
  color: #515a6e;
}

.ul-detail .inner {
  padding: 0.625rem 0 0.625rem 0.3rem;
}
.ul-detail .s3 .tit {
  min-width: 10em;
}
.ul-detail .s1 .tit {
  margin-right: 0;
  min-width: 5em;
}
.valuepile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.3rem 0 0;
}
.ul-detail li:first-child {
  padding-left: 1.3rem;
}
</style>

