<template>
  <div class="row-history">
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychaxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item input">
                  <el-input class="input" placeholder="请输入桩号" v-model="pileDescribe"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <!-- <el-main> -->
      <el-table :data="tableData" style="width: 100%" ref="table" row-key="id" @selection-change="selectionChange" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column fixed type="selection" align="center"></el-table-column>
        <el-table-column fixed prop="pileDescribe" label="桩号" min-width='70px' align='center' show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="deviceKey" label="设备编号" min-width='120px' align='center' show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="beginTime" label="开始时间" min-width='120px' align='center' show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.beginTime|datafilters }}
          </template>
        </el-table-column>
        <el-table-column prop="endTime" label="结束时间" min-width='120px' align='center' show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.endTime|datafilters }}
          </template>
        </el-table-column>
        <el-table-column prop="depth" label="深度(m)" min-width='100px' align='center' show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.depth|numfilters }}
          </template>
        </el-table-column>
        <el-table-column prop="partCount" label="组内数量(个)" min-width='100px' align='center' show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.partCount|numfilters(0) }}
          </template>
        </el-table-column>
        <el-table-column prop="longitude" label="经度" min-width='110px' align='center' show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.longitude|numfilters(3) }}
          </template>
        </el-table-column>
        <el-table-column prop="latitude" label="纬度" min-width='110px' align='center' show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.latitude|numfilters(3) }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="pileTime" label="组内时间(min)" min-width='110px' align='center' show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.pileTime|minfilters }}
          </template>
        </el-table-column> -->
        <el-table-column fixed="right">
          <template slot-scope="scope">
            <div class="m-edit-btns">
              <div class="item">
                <a href="javascript:" class="btn" @click="handleCommand(scope.$index)">详情</a>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- </el-main> -->
    </el-container>
    <!-- 详情弹出框 -->
    <el-dialog :visible.sync="centerDialogVisible" class="m-pop m-pop-detail">
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
        <!-- <div class="right">
          <el-select class="g-select-table" v-model="selectTable" placeholder="请选择记录表下载">
            <el-option v-for="item in selectTableList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
      </div>
      <div class="dialog-content">
        <div class="m-tab-table m-tab-detail" v-show="tab2 === 0">
          <div class="m-table-detail">
            <div class="detailitem">
              <h3>详细数据</h3>
              <div class="box">
                <ul class="ul-detail">
                  <li class="s1">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">桩号</div>
                        <div class="value">{{selectItem.pileDescribe}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">开始时间</div>
                        <div class="value">{{selectItem.beginTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">组内数量(个)</div>
                        <div class="value">{{selectItem.partCount}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="s2">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">桩机编号</div>
                        <div class="value">{{selectItem.deviceKey|worfilter}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">结束时间</div>
                        <div class="value">{{selectItem.endTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">经度</div>
                        <div class="value">{{selectItem.longitude}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="s3">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">深度(m)</div>
                        <div class="value">{{selectItem.depth|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">组内时间(min)</div>
                        <div class="value">{{selectItem.pileTime|minfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">纬度</div>
                        <div class="value">{{selectItem.latitude}}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <br />
            <h3>段数据列表</h3>
            <el-table ref="saveTable" class="m-table-save" :data="selectItem.data" style="width: 100%;margin-top:20px" height="32em">
              <el-table-column type="index" label="序号" min-width="70px" width="80px" align="center"></el-table-column>
              <el-table-column property="endTime" label="结束时间" min-width="200px" align="center">
                <template slot-scope="scope">
                  {{scope.row.endTime|datafilters}}
                </template>
              </el-table-column>
              <el-table-column property="xLocation" label="段位置X" min-width="105px" align="center"></el-table-column>
              <el-table-column property="yLocation" label="段位置Y" min-width="105px" align="center"></el-table-column>
              <el-table-column property="downDeep" label="下行深度(m)" min-width="102px" align="center">
                <template slot-scope="scope">
                  {{scope.row.downDeep|numfilters}}
                </template>
              </el-table-column>
              <el-table-column property="upDeep" label="回带深度(m)" min-width="120px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.upDeep|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="xAngle" label="倾角X" min-width="100px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.xAngle|numfilters(1) }}
                </template>
              </el-table-column>
              <el-table-column property="yAngle" label="倾角Y" min-width="100px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.yAngle|numfilters(1) }}
                </template>
              </el-table-column>
              <el-table-column property="pressure" label="段压力(Mpa)" min-width="117px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.pressure|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="rate" label="评价" min-width="70px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.rate|reaeFilter }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 1">
          <div class="m-pop-echart">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart03" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart02" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart04" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div> -->
      </div>
    </el-dialog>
    <el-footer class="m-selectAll">
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,prev, pager, next" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,sizes,jumper" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import moment from "moment";
import { historyList } from "@/api/api";
import gTabs from "@/components/gTabs";
import * as echarts from "echarts";
export default {
  components: {
    gTabs
  },
  props: ['tableData', 'deviceList', 'device', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'handleCurrentChange', 'toLastPage', 'toFirstPage'],
  data() {
    return {
      startDate: '',
      endDate: '',
      pileDescribe: "",
      deviceKey: '',
      selectItem: [],
      centerDialogVisible: false,
      tab2: 0,
      tab2List: [
        {
          name: "详细内容",
        },
        // {
        //   name: "图表曲线",
        // },
      ],
      totalChecked: false,
      // selectTable: "记录报表下载",
      // selectTableList: [
      //   {
      //     label: "记录报表下载",
      //     value: "0",
      //   },
      //   {
      //     label: "记录报表下载1",
      //     value: "1",
      //   },
      // ],
      inquire: [],
      totalNum: []
    }
  },
  filters: {
    reaeFilter(val) {
      if (val == 0) {
        return '合格'
      } else {
        return '无效'
      }
    },
    worfilter(val) {
      if (val != undefined) {
        return val.replace(/\u0000/g, '')
      } else {
        return val;
      }
    },
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val, deg) {
      if (deg == undefined) {
        deg = 2
      }
      if (isNaN(val)) {
        //val 不是数字
        return val
      } else {
        //val是数字
        return parseFloat(val).toFixed(deg)
      }
    },
    minfilters(val) {
      return Math.ceil(val / 60)
    },
  },
  watch: {
    centerDialogVisible(val, oldval) {
      if (val == false) {
        this.tab2 = 0
        if (this.myChart01 == undefined) return
        this.myChart01.dispose()
        this.myChart02.dispose()
        this.myChart03.dispose()
        this.myChart04.dispose()
      }
    }
  },
  mounted() {
    this.deviceKey = this.device
  },
  methods: {
    querychaxun() {
      historyList('DP', 0, this.deviceKey, this.pageSize, this.pileDescribe, this.startDate, this.endDate).then((res) => {
        this.inquire = res.content;
        this.totalNum = res.totalElements;
        this.$emit('changeDevicekey', this.deviceKey, this.pileDescribe, this.startDate, this.endDate, this.inquire, this.totalNum)
      });
    },
    resetClick() {
      this.deviceKey = "";
      this.pileDescribe = ''
      this.startDate = ''
      this.endDate = ''
    },

    changeHandle(index) {
      let self = this;
      this.tab2 = index;
    },
    // 详情点击
    handleCommand(index) {
      // 数据暂存在selectItem中
      this.selectItem = this.tableData[index];
      this.centerDialogVisible = true;
    },
    // 选中发生变化
    selectionChange(e) {
      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },
  }
}
</script>
<style scoped>
.ul-detail li {
  width: 33%;
}
/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.3rem 0 0;
}
.ul-detail li:first-child {
  padding-left: 1.5rem;
}
</style>
