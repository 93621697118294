<template>
  <div class="row-history">
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
                <el-button type="primary" @click="showData()">选择参数</el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item input">
                  <el-input class="input" placeholder="请输入桩号" v-model="number"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <el-main class="m-table-main">
        <div id="table-content">
          <el-table :data="tableData" style="width: 100%" ref="table" row-key="id" @selection-change="selectionChange" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
            <el-table-column type="selection" align='center' fixed></el-table-column>
            <el-table-column :width="tableWidth(index)" v-for="(col,index) in cols" :key="index" :prop="col.prop" :label="col.label" :formatter="col.formatter" min-width='135px' align='center' show-overflow-tooltip> </el-table-column>
            <el-table-column fixed="right">
              <template slot-scope="scope">
                <div class="m-edit-btns">
                  <div class="item">
                    <a href="javascript:" class="btn" @click="handleCommand(scope.$index)">详情</a>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-main>
      <el-dialog title="选择需要展示的参数" :visible.sync="dialogFormVisible" width="40%" center top="15vh">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedFileds" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in fieldNames" :label="item.prop" :key="item.prop">{{ item.label }}</el-checkbox>
        </el-checkbox-group>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="medium">取 消</el-button>
          <el-button type="primary" @click="confirmShow" size="medium">确定</el-button>
        </div>
      </el-dialog>
    </el-container>
    <!-- 详情弹出框 -->
    <el-dialog :visible.sync="centerDialogVisible" class="m-pop m-pop-detail">
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
        <div class="right">
          <el-button v-if="showdownloadBtn" type="primary" size="small" round @click="download">图片下载</el-button>
        </div>
      </div>
      <div class="dialog-content">
        <div class="m-tab-table m-tab-detail" v-show="tab2 === 0">
          <div class="m-table-detail">
            <div class="detailitem">
              <h3>详细数据</h3>
              <div class="box">
                <ul class="ul-detail">
                  <li class="s1">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">桩号</div>
                        <div class="value">{{selectItem.pileDescribe}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">桩机编号</div>
                        <div class="value">{{selectItem.machineKey}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">开始时间</div>
                        <div class="value">{{selectItem.beginTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">结束时间</div>
                        <div class="value">{{selectItem.endTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计浆量(L)</div>
                        <div class="value">{{selectItem.cumulativePulp|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">平均浆量(L/m)</div>
                        <div class="value">{{selectItem.averagePulp|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计灰量(kg)</div>
                        <div class="value">{{selectItem.cumulativeAsh|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">平均灰量(kg/m)</div>
                        <div class="value">{{selectItem.averageAsh|numfilters}}</div>
                      </div>

                    </div>
                  </li>
                  <li class="s2">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">累计浆量1(L)</div>
                        <div class="value">{{selectItem.cumulativePulp1|numfilters}}</div>
                      </div>

                      <div class="item">
                        <div class="tit">平均浆量1(L/m)</div>
                        <div class="value">{{selectItem.averagePulp1|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计灰量1(kg)</div>
                        <div class="value">{{selectItem.cumulativeAsh1|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">平均灰量1(kg/m)</div>
                        <div class="value">{{selectItem.averageAsh1|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计浆量2(L)</div>
                        <div class="value">{{selectItem.cumulativePulp2|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">平均浆量2(L/m)</div>
                        <div class="value">{{selectItem.averagePulp2|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计灰量2(kg)</div>
                        <div class="value">{{selectItem.cumulativeAsh2|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">平均灰量2(kg/m)</div>
                        <div class="value">{{selectItem.averageAsh2|numfilters}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="s3">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">水灰比</div>
                        <div class="value">{{selectItem.waterCementRatio|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">成桩时间(min)</div>
                        <div class="value">{{selectItem.pileTime|minfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">施工桩长(m)</div>
                        <div class="value">{{selectItem.depth|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">初搅深度(m)</div>
                        <div class="value">{{selectItem.depth|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">复搅深度(m)</div>
                        <div class="value">{{selectItem.reDepth|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">扩大头桩长(m)</div>
                        <div class="value">{{selectItem.tTypeLength|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">下钻速度(cm/min)</div>
                        <div class="value">{{selectItem.downSpeed|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">提钻速度(cm/min)</div>
                        <div class="value">{{selectItem.upSpeed|numfilters}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="s4">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">垂直度</div>
                        <div class="value">{{selectItem.verticality|num1filters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">桩顶海拔</div>
                        <div class="value">{{selectItem.topAltitude|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">桩底海拔</div>
                        <div class="value">{{selectItem.bottomAltitude|numfilters}}</div>
                      </div>

                      <div class="item">
                        <div class="tit">上部桩浆量</div>
                        <div class="value">{{selectItem.tTypeSlurry|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">下部桩浆量</div>
                        <div class="value">{{selectItem.bottomPartSlurry|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">上部桩灰量</div>
                        <div class="value">{{selectItem.tTypeAsh|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">下部桩灰量</div>
                        <div class="value">{{selectItem.bottomPartAsh|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">成桩经纬度</div>
                        <div class="value">{{selectItem.holeLongitude+','+selectItem.holeLatitude}}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <br />
            <h3>段数据列表</h3>
            <el-table ref="saveTable" class="m-table-save m_table_save_height" :data="selectItem.data" style="width: 100%;margin-top:20px" height="23em">
              <el-table-column property="partId" label="序号" min-width="5%" align="center">
              </el-table-column>
              <el-table-column property="partDeep" :label="'段深度\nm'" min-width="7.5%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partDeep|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partAltitude" :label="'段海拔\nm'" min-width="7.5%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partAltitude|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partPulp1" :label="'段浆量1\nL'" min-width="8%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partPulp1|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partPulp2" :label="'段浆量2\nL'" min-width="7.5%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partPulp2|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partPulp" :label="'总段浆量\nL'" min-width="8%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partPulp|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partAsh1" :label="'段灰量1\nkg'" min-width="7.5%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partAsh1|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partAsh2" :label="'段灰量2\nkg'" min-width="8%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partAsh2|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partAsh" :label="'总段灰量\nkg'" min-width="8.33%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partAsh|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partDownSpeed" :label="'速度\ncm/min'" min-width="9%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partDownSpeed|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partTime" :label="'段时长\ns'" min-width="8.33%" align="center">
              </el-table-column>
              <el-table-column property="partDownSpeed" label="状态" min-width="5%" align="center">
                <template slot-scope="scope">
                  {{scope.row.partDownSpeed|num5filters}}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 1">
          <div class="m-pop-echart">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart04" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="12" class="col col_3">
                <div class="inner">
                  <div ref="myChart02" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="echartDownload">
            <div ref="myChart05" :style="{ width: '100%', height: '50%' }"></div>
            <div ref="myChart06" :style="{ width: '100%', height: '50%' }"></div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-footer class="m-selectAll">
      <div class="left">
        <div class="item">
          <el-checkbox v-model="totalChecked" class="total" @change="totalChange">全选
          </el-checkbox>
        </div>
        <div class="item">
          <a href="javascript:;" class="export">导出</a>
        </div>
      </div>
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,sizes, jumper" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import moment from "moment";
import { historyList } from "@/api/api"
import gTabs from "@/components/gTabs";
import * as echarts from "echarts";
const allField = ['pileDescribe', 'deviceKey', 'beginTime', 'endTime', 'depth', 'frDepth', 'reDepth', 'waterCementRatio',
  'cumulativePulp', 'averagePulp', 'cumulativeAsh', 'averageAsh', 'verticality', 'maxDownSpeed', 'downSpeed', 'maxUpSpeed', 'upSpeed',
  'pileTime']; //所有的数据

export default {
  components: {
    gTabs
  },
  props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'handleCurrentChange', 'toLastPage', 'toFirstPage', 'pileDescribe'],
  data() {
    return {
      totalChecked: false,
      startDate: '',
      endDate: '',
      number: "",
      deviceKey: '',

      checkAll: false,  //默认全选
      checkedFileds: ['pileDescribe', 'deviceKey', 'beginTime', 'endTime', 'depth', 'cumulativePulp', 'averagePulp', 'cumulativeAsh', 'pileTime', 'verticality',], //默认全选所有的数据
      fieldNames: [{
        prop: 'pileDescribe',
        label: '桩号'
      },
      {
        prop: 'deviceKey',
        label: '桩机号'
      },
      {
        prop: 'beginTime',
        label: '开始时间',
        formatter: this.testFormatter,
      },
      {
        prop: 'endTime',
        label: '结束时间',
        formatter: this.testFormatter,
      },
      {
        prop: 'depth',
        label: '施工桩长(m)',
        formatter: this.numFormatter
      },
      {
        prop: 'frDepth',
        label: '初搅深度(m)',
        formatter: this.numFormatter
      },
      {
        prop: 'reDepth',
        label: '复搅深度(m)',
        formatter: this.numFormatter
      },
      {
        prop: 'waterCementRatio',
        label: '水灰比',
        formatter: this.numFormatter
      },
      {
        prop: 'cumulativePulp',
        label: '累计浆量(L)',
        formatter: this.numFormatter
      },
      {
        prop: 'averagePulp',
        label: '平均浆量(L/m)',
        formatter: this.numFormatter
      },
      {
        prop: 'cumulativeAsh',
        label: '累计灰量(kg)',
        formatter: this.numFormatter
      },
      {
        prop: 'averageAsh',
        label: '平均灰量(kg/m)',
        formatter: this.numFormatter
      },
      {
        prop: 'verticality',
        label: '垂直度',
        formatter: this.num1Formatter
      },
      {
        prop: 'maxDownSpeed',
        label: '最大钻速(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'downSpeed',
        label: '下钻速度(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'maxUpSpeed',
        label: '最大提速(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'upSpeed',
        label: '提钻速度(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'pileTime',
        label: '成桩时间(min)',
        formatter: this.minFormatter
      },
      ],   //对象数组全部的数据
      isIndeterminate: false,   //默认全选
      dialogFormVisible: false,
      cols: [
        {
          prop: 'pileDescribe',
          label: '桩号'
        },
        {
          prop: 'deviceKey',
          label: '桩机号'
        },

        {
          prop: 'beginTime',
          label: '开始时间',
          formatter: this.testFormatter,
        },
        {
          prop: 'endTime',
          label: '结束时间',
          formatter: this.testFormatter,
        },
        {
          prop: 'depth',
          label: '施工桩长(m)',
          formatter: this.numFormatter
        },
        {
          prop: 'cumulativePulp',
          label: '累计浆量(L)',
          formatter: this.numFormatter
        },
        {
          prop: 'averagePulp',
          label: '平均浆量(L/m)',
          formatter: this.numFormatter
        },
        {
          prop: 'cumulativeAsh',
          label: '累计灰量(kg)',
          formatter: this.numFormatter
        },
        {
          prop: 'pileTime',
          label: '成桩时间(min)',
          formatter: this.minFormatter
        },
        {
          prop: 'verticality',
          label: '垂直度',
          formatter: this.num1Formatter
        },
      ],
      tempCols: [],  //临时的数据容器
      centerDialogVisible: false,// 弹窗
      // 点击详情
      echartsdata: [],
      print: [],
      selectItem: [],
      // centerDialogVisible: false,
      tab2: 0,
      tab2List: [
        {
          name: "详细内容",
        },
        {
          name: "图表曲线",
        },
      ],
      selectTable: "记录报表下载",
      // selectTableList: [
      //   {
      //     label: "记录报表下载",
      //     value: "0",
      //   },
      //   {
      //     label: "记录报表下载1",
      //     value: "1",
      //   },
      // ],
      inquire: [],
      totalNum: 0,
      showdownloadBtn: false,
      echart: [],
      downloadEchart: []
    }
  },
  filters: {
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val) {
      return parseFloat(val).toFixed(2)
    },
    minfilters(val) {
      return (parseFloat(val) / 60).toFixed(0)
    },
    num1filters(val) {
      return parseFloat(val).toFixed(1) + '%'
    },
    num4filters(val) {
      return Math.abs(parseFloat(val).toFixed(2))
    },
    num5filters(val) {
      return val < 0 ? '提钻' : '下钻'
    }
  },
  watch: {
    centerDialogVisible(val, oldval) {
      if (val == false) {
        this.showdownloadBtn = false
        this.tab2 = 0
        this.echartsdata = []
        this.downloadEchart = []
        this.echart = []
        if (this.myChart01 == undefined || this.myChart01._disposed) return
        this.myChart01.dispose()
        this.myChart02.dispose()
        this.myChart04.dispose()
        this.myChart05.dispose()
        this.myChart06.dispose()
      }
    }
  },
  mounted() {
    this.deviceKey = this.device
  },
  methods: {
    download() {
      let a = document.createElement('a');
      a.href = this.myChart05.getConnectedDataURL({
        pixelRatio: 2,
        backgroundColor: '#fff'
      })
      a.download = "图片下载.png";
      a.click();
    },
    tableWidth(index) {
      if (index == 1 || index == 2 || index == 3) {
        return 150
      }
    },
    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      historyList('DK', 0, this.deviceKey, this.pageSize, this.number, this.startDate, this.endDate).then((res) => {
        this.inquire = res.content;
        this.totalNum = res.totalElements;
        this.$emit('changeDevicekey', this.deviceKey, this.number, this.startDate, this.endDate, this.inquire, this.totalNum)
        loading.close()
      });
      setTimeout(() => {
        loading.close()
      })
    },
    resetClick() {
      this.deviceKey = "";
      this.number = '', this.startDate = '', this.endDate = ''
    },

    testFormatter(row, column, cellValue, index) {
      return moment(cellValue * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numFormatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(2)
    },
    minFormatter(row, column, cellValue, index) {
      return (parseFloat(cellValue) / 60).toFixed(0)
    },
    num1Formatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(2) + '%'
    },

    //全选按钮
    handleCheckAllChange(val) {
      this.checkedFileds = val ? allField : [];
      this.isIndeterminate = false;
    },
    //单个选中
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fieldNames.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fieldNames.length;
    },
    showData(row) {
      this.dialogFormVisible = true;
    },
    confirmShow() {
      this.tempCols = [];
      if (this.checkedFileds.length < 1) {
        this.$message.warning("请至少选择一项数据");
        this.dialogFormVisible = true;
      } else {
        for (var i = 0; i < this.fieldNames.length; i++) {
          for (var j = 0; j < this.checkedFileds.length; j++) {
            if (this.fieldNames[i].prop == this.checkedFileds[j]) {
              this.tempCols.push(this.fieldNames[i]);
            }
          }
        }
        this.cols = this.tempCols;
        this.dialogFormVisible = false;
      }
    },
    // 选中发生变化
    selectionChange(e) {
      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },

    // 图表
    initEchart05() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep.toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], Number(item.partAltitude.toFixed(2))];
      });
      let option = {
        title: {
          text: "深度曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "海拔"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度          ",
            inverse: true,
            nameLocation: "end",
            type: "value",
            max: 40,
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            axisLine: {
              show: true
            }
          },
          {
            name: "    海拔",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
            splitLine: {
              show: false
            }
            // max: 100
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "海拔",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart05 = echarts.init(this.$refs.myChart05, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart05.setOption(option);
      this.downloadEchart.push(this.myChart05)
    },
    initEchart06() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp / item.partTime * 60).toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDownSpeed.toFixed(2)];
      });
      let option = {
        title: {
          text: "流量、速度变化曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["流量", "速度"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "流量          ",
            inverse: false,
            type: "value",
            nameLocation: "start", // 设置坐标轴名称显示位置
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            axisLine: {
              show: true
            }
          },
          {
            name: "          速度",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            max: 50,
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
            splitLine: {
              show: false
            }
          },
        ],
        series: [
          {
            name: "流量",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "速度",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart06 = echarts.init(this.$refs.myChart06, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart06.setOption(option);
      this.downloadEchart.push(this.myChart06)
    },
    initEchart01() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep.toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], Number(item.partAltitude.toFixed(2))];
      });
      let option = {
        title: {
          text: "深度曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "海拔"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度",
            inverse: true,
            nameLocation: "end",
            type: "value",
            max: 40,
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            axisLine: {
              show: true
            }
          },
          {
            name: "海拔",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
            splitLine: {
              show: false
            }
            // max: 100
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "海拔",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart01.setOption(option);
      this.echart.push(this.myChart01)
    },
    initEchart02() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp / item.partTime * 60).toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDownSpeed.toFixed(2)];
      });
      let option = {
        title: {
          text: "流量、速度变化曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["流量", "速度"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "流量",
            inverse: false,
            type: "value",
            nameLocation: "start", // 设置坐标轴名称显示位置
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            axisLine: {
              show: true
            }
          },
          {
            name: "速度",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            max: 50,
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
            splitLine: {
              show: false
            }
          },
        ],
        series: [
          {
            name: "流量",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "速度",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart02 = echarts.init(this.$refs.myChart02, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart02.setOption(option);
      this.echart.push(this.myChart02)
    },
    initEchart04() {
      var ash = []
      for (var k = 0; k < this.selectItem.data.length; k++) {
        if (ash[Math.ceil(this.selectItem.data[k].partDeep - 1)] == undefined) {
          ash[Math.ceil(this.selectItem.data[k].partDeep - 1)] = 0
        }
        ash[Math.ceil(this.selectItem.data[k].partDeep - 1)] += this.selectItem.data[k].partAsh
      }
      var numbers2 = ash.map(myFunction);
      function myFunction(value, index, array) {
        var data = {
          value: Number(value.toFixed(2)),
          name: index + 1
        }
        return data;
      }
      let option = {
        title: {
          text: "成桩灰量分布",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} : {c}",
        },
        grid: {
          top: "10%",
          bottom: "10%",
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        series: [
          {
            name: "成桩灰量分布",
            type: "funnel",
            width: "50%",
            left: "center",
            top: "15%",
            bottotm: "5%",
            label: {
              show: true,
            },
            labelLine: {
              show: true,
            },
            data: numbers2,
            sort: "none",
            color: '#5470c6'
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart04 = echarts.init(this.$refs.myChart04, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart04.setOption(option);
    },
    // 详情tab切换
    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index == 0) {
        this.showdownloadBtn = false
      }
      if (index === 1) {
        this.showdownloadBtn = true
        this.$nextTick(() => {
          this.initEchart05();
          this.initEchart06();
          echarts.connect(this.downloadEchart)
          this.initEchart01();
          this.initEchart02();
          this.initEchart04();
          echarts.connect(this.echart)
          window.onresize = function () {
            self.myChart01.resize();
            self.myChart02.resize();
            self.myChart04.resize();
          };
        });
      }
    },
    // 详情点击
    handleCommand(index) {
      // 数据暂存在selectItem中
      this.selectItem = this.tableData[index];
      this.selectItem.holeLongitude = Number(this.selectItem.holeLongitude.toFixed(7))
      this.selectItem.holeLatitude = Number(this.selectItem.holeLatitude.toFixed(7))
      this.print = this.tableData[index].print
      this.centerDialogVisible = true;
      var day = this.selectItem.beginTime
      var time
      for (var i = 0; i < this.tableData[index].data.length; i++) {
        day += this.tableData[index].data[i].partTime
        time = moment(day * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.echartsdata.push(time)
      }
      var startTime = this.selectItem.beginTime
      var serialNumber = []
      for (var j = 0; j < this.selectItem.data.length; j++) {
        this.selectItem.data[j].startDay = moment(startTime * 1000).format('YYYY-MM-DD HH:mm:ss')
        startTime += this.selectItem.data[j].partTime
        if (startTime == this.selectItem.data[j]) {
          this.selectItem.data[j].serial = serialNumber.push(j)
        }
      }
      // var ser = this.selectItem.data.partTime
      // var serialNumber = []
      // for (var k = 0; k < this.selectItem.data.length; k++) {
      //   if (ser == this.selectItem.data[k].partTime) {
      //     this.selectItem.data[k].serial = serialNumber.push(k)
      //   }
      // }
    },

    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },
  }
}
</script>
<style scoped>
.m-tab-echartTable {
  position: relative;
}
.echartDownload {
  position: absolute;
  top: 0;
  left: -2000px;
  width: 800px;
  height: 660px;
}
.col_3 {
  width: 100%;
}
.el-checkbox {
  width: 20%;
}
.el-button--primary {
  margin-left: 45px;
  margin-right: 5px;
}
.m-filter .filter .btn {
  margin-left: 2.5rem;
}
/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.3rem 0 0;
}
.ul-detail li:first-child {
  padding-left: 1.3rem;
}
.ul-detail .s4 .tit {
  min-width: 6em;
}
.ul-detail .inner {
  padding: 0.625rem 0.5rem;
}
</style>
