<template>
  <div class="row-history">
    <component :is="report" :ARDreportHistory='ARDreportHistory' :selectItem='selectItem'></component>
    <a id="simulation" href=""></a>
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" multiple filterable reserve-keyword placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item input">
                  <el-input class="input" placeholder="请输入桩号" v-model="number"></el-input>
                </el-form-item>

                <!-- <el-button @click="btn3D">三维影像</el-button> -->
                <el-button @click="btnMap">桩点地图</el-button>

              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <el-main>
        <el-table :data="tableData" style="width: 100%" ref="table" row-key="id" @selection-change="selectionChange" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
          <el-table-column fixed type="selection" align="center"></el-table-column>
          <el-table-column fixed prop="pileDescribe" label="作业序号" min-width='280px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              <a href="javascript:" class="CopyStyle el-icon-document-copy" @click="copytData(scope.row.pileDescribe)"></a>{{ scope.row.pileDescribe}}
            </template>
          </el-table-column>
          <el-table-column prop="deviceName" label="设备名称" min-width='170px' align='center' show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="deviceKey" label="设备编号" min-width='160px' align='center' show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="beginTime" label="开始时间" min-width='160px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.beginTime|datafilters }}
            </template>
          </el-table-column>
          <el-table-column prop="endTime" label="结束时间" min-width='160px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.endTime|datafilters }}
            </template>
          </el-table-column>
          <el-table-column prop="designPileLength" label="设计深度(m)" min-width='110px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.designPileLength|numfilters}}
            </template>
          </el-table-column>
          <el-table-column prop="" label="桩底高程" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row|pileTopFilter}}
            </template>
          </el-table-column>
          <el-table-column prop="maxDepth" label="成槽深度(m)" min-width='110px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.maxDepth|numfilters}}
            </template>
          </el-table-column>
          <el-table-column prop="workWidth" label="横移距离(m)" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.workWidth|workWidthFilter}}
            </template>
          </el-table-column>
          <el-table-column prop="maxPressure1" label="最大拉力(MPa)" min-width='130px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.maxPressure1|numfilters}}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="verticality" label="垂直度(%)" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.verticality|numfilters(1) }}
            </template>
          </el-table-column> -->
          <el-table-column prop="" label="成槽时间(min)" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row|piletimeFilter}}
            </template>
          </el-table-column>
          <el-table-column fixed="right">
            <template slot-scope="scope">
              <div class="m-edit-btns">
                <div class="item">
                  <a href="javascript:" class="btn" @click="handleCommand(scope.$index)">详情</a>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <!-- 桩点地图 -->
      <el-dialog :visible.sync='pileMapDialog' width="65%" top=2vh class="dialogHeight">
        <div slot="title" class="dialogMap_header">
          <el-select v-model="MapDeviceKey" placeholder="请选择设备" clearable style="marginRight:15px">
            <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key"></el-option>
          </el-select>
          <el-date-picker v-model="MapbeginTime" value-format="timestamp" type="datetime" placeholder="选择日期时间">
          </el-date-picker>
          <div class="item" style="lineHeight:39px;margin:0 8px">至</div>
          <el-date-picker v-model="MapendTime" value-format="timestamp" type="datetime" placeholder="选择日期时间">
          </el-date-picker>
          <el-button style="marginLeft:10px" @click="Mapbtn">确定</el-button>
        </div>
        <div class="D3Echart">
          <div class="tdtMap">
            <amap :center='center' :zoom="10">
              <amap-marker v-for="(item, index) of positions" :key="index" :position="item.position" :icon="item.icon" :offset=[-16,-16]></amap-marker>
              <!-- <amap-polyline :path="path" :stroke-weight="7" stroke-color="#32AC2E" is-outline :border-weight="2" outline-color="#fff" /> -->
              <!-- <amap-polyline :path="path2" :stroke-weight="7" stroke-color="#0E8ADB" is-outline :border-weight="2" outline-color="#fff" /> -->
            </amap>
          </div>
        </div>
      </el-dialog>

      <!-- 弹出框 -->
      <el-dialog :visible.sync="centerDialogVisible" class="m-pop m-pop-detail">
        <div slot="title" class="dialog-header">
          <div class="left">
            <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
          </div>
        </div>
        <div class="dialog-content">
          <div class="m-tab-table m-tab-detail" v-show="tab2 === 0">
            <div class="m-table-detail">
              <div class="detailitem">
                <h3>详细数据</h3>
                <div class="box">
                  <ul class="ul-detail">
                    <li class="s1">
                      <div class="inner">
                        <div class="item">
                          <div class="tit">作业序号</div>
                          <div class="value" :title="selectItem.pileDescribe">{{selectItem.pileDescribe}}</div>
                        </div>
                        <div class="item">
                          <div class="tit">桩机编号</div>
                          <div class="value">{{selectItem.deviceKey}}</div>
                        </div>
                        <div class="item">
                          <div class="tit">开始时间</div>
                          <div class="value">{{selectItem.beginTime|datafilters}}</div>
                        </div>
                        <div class="item">
                          <div class="tit">结束时间</div>
                          <div class="value">{{selectItem.endTime|datafilters}}</div>
                        </div>
                        <div class="item">
                          <div class="tit">设计深度</div>
                          <div class="value">{{selectItem.designPileLength|numfilters}}m</div>
                        </div>
                      </div>
                    </li>
                    <li class="s2">
                      <div class="inner">
                        <div class="item">
                          <div class="tit">成槽深度</div>
                          <div class="value">{{selectItem.maxDepth|numfilters}} m</div>
                        </div>

                        <div class="item">
                          <div class="tit">横移距离</div>
                          <div class="value">{{selectItem.workWidth|numfilters}} m</div>
                        </div>

                        <div class="item">
                          <div class="tit">抓挖次数</div>
                          <div class="value">{{selectItem.rotationNum|numfilters(0)}} 次</div>
                        </div>
                        <div class="item">
                          <div class="tit">抓挖方量</div>
                          <div class="value">{{selectItem.pileSquare|numfilters}} m³</div>
                        </div>
                        <div class="item">
                          <div class="tit">成槽面积</div>
                          <div class="value">{{selectItem.pileSquarem2|numfilters}}㎡</div>
                        </div>
                      </div>
                    </li>
                    <li class="s3">
                      <div class="inner">
                        <div class="item">
                          <div class="tit">垂直度</div>
                          <div class="value">{{selectItem.verticality|numfilters}} %</div>
                        </div>
                        <div class="item">
                          <div class="tit">最大拉力</div>
                          <div class="value">{{selectItem.maxPressure1|numfilters}} MPa</div>
                        </div>
                        <div class="item">
                          <div class="tit">桩顶高程</div>
                          <div class="value">{{selectItem.pileTopelevation|numfilters}} m</div>
                        </div>
                        <div class="item">
                          <div class="tit">桩底高程</div>
                          <div class="value">{{selectItem.pileBottomElevation|numfilters}} m</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
              <h3>段数据列表</h3>
              <u-table ref="saveTable" :border='false' use-virtual class="m-table-save m_table_save_height" :data="selectItem.datas" height=480>
                <u-table-column type="index" label="序号" width="100px" align='center'>
                </u-table-column>
                <u-table-column property="startDay" label="时间" min-width="8.33%" align='center'>
                </u-table-column>
                <u-table-column property="partDepth" label="深度(m)" min-width="8.33%" align='center'>
                  <template slot-scope="scope">
                    {{ scope.row.partDepth|numfilters }}
                  </template>
                </u-table-column>
                <u-table-column property="partSpeed" label="速度(cm/min)" min-width="8.33%" align='center'>
                  <template slot-scope="scope">
                    {{ scope.row.partSpeed|Speedfilters }}
                  </template>
                </u-table-column>
                <u-table-column property="partPressure1" label="拉力(MPa)" min-width="8.33%" align='center'>
                  <template slot-scope="scope">
                    {{ scope.row.partPressure1|numfilters }}
                  </template>
                </u-table-column>
                <u-table-column property="partVerticality" label="垂直度(%)" min-width="8.33%" align='center'>
                  <template slot-scope="scope">
                    {{scope.row.partVerticality|numfilters}}
                  </template>
                </u-table-column>
                <u-table-column property="partTime" label="段时长(s)" min-width="8.33%" align='center'>
                </u-table-column>
              </u-table>
            </div>
          </div>
          <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 1">
            <div class="m-pop-echart">
              <div class="inner">
                <div ref="myChart03" :style="{ width: '100%', height: '100%' }"></div>
              </div>
            </div>
          </div>
          <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 2">
            <div class="m-pop-echart">
              <el-row class="row">
                <el-col :span="12" class="col">
                  <div class="inner">
                    <div ref="myChart02" :style="{ width: '100%', height: '100%' }"></div>
                  </div>
                </el-col>
                <el-col :span="12" class="col">
                  <div class="inner">
                    <div ref="myChart04" :style="{ width: '100%', height: '100%' }"></div>
                  </div>
                </el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="12" class="col">
                  <div class="inner">
                    <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                  </div>
                </el-col>
                <el-col :span="12" class="col">
                  <div class="inner">
                    <div ref="myChart05" :style="{ width: '100%', height: '100%' }"></div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-dialog>

      <el-footer class="m-selectAll">
        <div class="left">
          <div class="item">
            <el-checkbox v-model="totalChecked" class="total" @change="totalChange">
              全选
            </el-checkbox>
          </div>
          <div class="item">
            <div class="export" id="simulations" href="" @click="exportAll">导出
            </div>
          </div>
        </div>
        <div class="right">
          <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,prev, pager, next" :total="total">
            <!-- slot部分，跳转首页 -->
            <button class="lastPage" @click="toFirstPage">
              <i class="el-icon-d-arrow-left"></i>
            </button>
          </el-pagination>
          <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot, jumper, sizes" :total="total">
            <!-- slot部分，跳转末页 -->
            <button class="lastPage" @click="toLastPage">
              <i class="el-icon-d-arrow-right"></i>
            </button>
          </el-pagination>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import report from '../report/report.vue'
import moment from "moment";
import { historyList, pileMap } from "@/api/api";
import gTabs from "@/components/gTabs";
import * as echarts from "echarts";
import 'echarts-gl';
import GPS from '@/utils/GpstoGD'
import tableToExcel from '../../utils/report'
import axios from 'axios'
export default {
  components: {
    gTabs, report
  },
  props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'handleCurrentChange', 'toLastPage', 'toFirstPage', 'pileDescribe'],
  data() {
    return {
      report: 'report',
      ARDreportHistory: [],
      MapDeviceKey: '', //地图选择设备
      MapbeginTime: '',
      MapendTime: '',

      path: [[0, 0]],

      height: '28em',
      PartDatas: [],
      partXY: [],
      length: '',
      actualLength: '',
      totalChecked: false,
      startDate: '',
      endDate: '',
      number: "",
      deviceKey: '',
      echartsdata: [],
      selectItem: [],
      centerDialogVisible: false,
      tab2: 0,
      tab2List: [{ name: "详细内容", }, { name: "三维影像", }, { name: "图表曲线", }],
      inquire: [],
      totalNum: 0,
      echart: [],
      echart3DColor: [],
      pileMapDialog: false,//地图显示
      center: [0, 0],
      positions: [],
    }
  },
  filters: {
    pileTopFilter(val) {
      let datas = parseFloat((val.altitude - val.designAntLength - val.maxDepth).toFixed(2))
      if (isNaN(datas)) {
        return '-'
      } else {
        return datas
      }
    },
    workWidthFilter(val) {
      return val == undefined ? 0 : parseFloat(Number(val)).toFixed(2)
    },
    piletimeFilter(val) {
      return (parseFloat((val.endTime - val.beginTime) / 1000) / 60).toFixed(0)
    },
    datafilters(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val, deg) {
      if (deg == undefined) {
        deg = 2
      }
      if (isNaN(Number(val))) {
        return 0
      } else {
        return parseFloat(Number(val)).toFixed(deg)
      }
    },
    Speedfilters(val) {
      return Math.abs(Number(val).toFixed(2))
    }
  },
  watch: {
    centerDialogVisible(val, oldval) {
      if (val == false) {
        this.selectItem = []
        this.echartsdata = []
        this.partXY = []
        this.tab2 = 0
        if (this.myChart01 === undefined || this.myChart01._disposed) return
        this.myChart01.dispose()
        this.myChart02.dispose()
      }
    },
    pileMapDialog(val) {
      if (val == false) {
        let beT = moment(new Date()).format('YYYY-MM-DD 00:00:00')
        this.MapbeginTime = new Date(beT).getTime()
        this.MapendTime = new Date().getTime()
        this.MapDeviceKey = ''
      }
    }
  },
  mounted() {
    let beT = moment(new Date()).format('YYYY-MM-DD 00:00:00')
    this.MapbeginTime = new Date(beT).getTime()
    this.MapendTime = new Date().getTime()
    if (this.device == '') {
      this.deviceKey = ''
    } else {
      this.deviceKey = [this.device]
    }
  },
  methods: {
    Mapbtn() {
      this.positions = []
      this.getMapPile()
    },
    btnMap() {
      this.pileMapDialog = true
      this.getMapPile()
    },
    getMapPile() {
      pileMap(this.MapDeviceKey, this.MapbeginTime, this.MapendTime).then(res => {
        this.path = []
        let mapData = []
        mapData = res
        if (res.length != 0) {
          this.center = [mapData[0].longitude, mapData[0].latitude]
          // let a = [], b = []
          for (let i = 0; i < mapData.length; i++) {
            const mark = GPS.gcj_encrypt(
              Number(mapData[i].latitude),
              Number(mapData[i].longitude)
            )
            let obj = {
              icon: {
                image: require("@/assets/images/map/dian.png"),
                size: [32, 32,],
                imageSize: [32, 32],
              },
              // icon: require("@/assets/images/map/dian.png"),
              position: [mark.lng, mark.lat],
            }
            this.positions.push(obj)
          }
        }

      })
    },


    copytData(msg) {
      const save = function (e) {
        e.clipboardData.setData('text/plain', msg)
        e.preventDefault() // 阻止默认行为
      }
      const once = {
        once: true
      }
      document.addEventListener('copy', save, once) // 添加一个copy事件,当触发时执行一次,执行完删除
      document.execCommand('copy') // 执行copy方法
      this.$message({ message: '复制成功', type: 'success' })
    },
    // 查询
    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.deviceKey.length > 0) {
        var device = this.deviceKey.join()
      } else {
        device = ''
      }
      historyList('A_RD', 0, device, this.pageSize, this.number, this.startDate, this.endDate).then((res) => {
        this.inquire = res.content;
        let map = new Map()
        for (let i = 0; i < this.deviceList.length; i++) {
          map.set(this.deviceList[i].key, this.deviceList[i].name)
        }
        for (let i = 0; i < this.inquire.length; i++) {
          this.inquire[i].deviceName = map.get(this.inquire[i].deviceKey)
        }
        this.totalNum = res.totalElements;
        this.$emit('changeDevicekey', device, this.number, this.startDate, this.endDate, this.inquire, this.totalNum,)
        loading.close()
      });
      setTimeout(() => {
        loading.close()
      }, 6000)
    },
    // 重置
    resetClick() {
      this.deviceKey = ""
      this.number = ''
      this.startDate = ''
      this.endDate = ''
    },
    // 3D图
    initEchart03() {
      let option = {
        tooltip: {},
        backgroundColor: '#fff',
        visualMap: {
          show: false,
          min: -35,
          max: 0,
          borderColor: '#D60808',
          // color: this.echart3DColor
          color: ['#FCF1E0', '#F8E2C2', '#F2C685', '#EEB866', '#EBA948', '#E89B29', '#E6941A', '#D78A18', '#C78017', '#B87615', '#A96D13', '#996311',]
        },
        xAxis3D: {
          type: 'value',
          max: this.partXY[0],
          min: this.partXY[1]
        },
        yAxis3D: {
          type: 'value',
          max: this.partXY[2],
          min: this.partXY[3]
        },
        zAxis3D: {
          type: 'value'
        },
        grid3D: {
          viewControl: {
            // projection: 'orthographic'
          }
        },
        series: [
          {
            // type: "scatter3D",
            type: "bar3D",
            // type: 'surface',
            wireframe: {
              // show: false
            },
            data: this.PartDatas,
            itemStyle: {
              // color: '#e6e6e6'
              // color: "#999"
            }
          }
        ]
      }
      // 基于准备好的dom，初始化echarts实例
      this.myChart03 = echarts.init(this.$refs.myChart03, null, { devicePixelRatio: 2 });
      // 绘制图表
      this.myChart03.setOption(option);
      this.echart.push(this.myChart03)
    },
    initEchart02() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.datas.map((item, index) => {
        return [data[index], item.partDepth.toFixed(3)];
      });
      // let y2Data = this.selectItem.datas.map((item, index) => {
      //   return [data[index], item.partSpeed.toFixed(2) + ''];
      // });
      let option = {
        title: {
          text: "深度曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          top: "3%",
          right: "1%",
          feature: {
            saveAsImage: {}
          },
          showTitle: false
        },
        legend: {
          top: "5%",
          right: "4%",
          data: ["深度"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度",
            type: "value",
            inverse: true,
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          }
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart02 = echarts.init(this.$refs.myChart02, null, { devicePixelRatio: 2 });
      // 绘制图表
      this.myChart02.setOption(option);
      this.echart.push(this.myChart02)
    },
    initEchart04() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.datas.map((item, index) => {
        return [data[index], item.partSpeed.toFixed(3)];
      });
      // let y2Data = this.selectItem.datas.map((item, index) => {
      //   return [data[index], item.partSpeed.toFixed(2) + ''];
      // });
      let option = {
        title: {
          text: "速度曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        // toolbox: {
        //   top: "3%",
        //   right: "1%",
        //   feature: {
        //     saveAsImage: {}
        //   },
        //   showTitle: false
        // },
        legend: {
          top: "5%",
          right: "3%",
          data: ["速度"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "速度",
            type: "value",
            // inverse: true,
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          }
        ],
        series: [
          {
            name: "速度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart04 = echarts.init(this.$refs.myChart04, null, { devicePixelRatio: 2 });
      // 绘制图表
      this.myChart04.setOption(option);
      this.echart.push(this.myChart04)
    },
    initEchart01() {
      let data = this.echartsdata;
      let y2Data = this.selectItem.datas.map((item, index) => {
        return [data[index], Number(item.partPressure1).toFixed(3) + ''];
      });
      let option = {
        title: {
          text: "抓斗拉力曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "3%",
          data: ["拉力"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        // toolbox: {
        //   right: "3%",
        //   top: "3%",
        //   // feature: {
        //   //   saveAsImage: {},
        //   // },
        // },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "拉力",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "拉力",
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01, null, { devicePixelRatio: 2 });
      // 绘制图表
      this.myChart01.setOption(option);
      this.echart.push(this.myChart01)
    },
    initEchart05() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.datas.map((item, index) => {
        return [data[index], item.partHeading.toFixed(3)];
      });
      // let y2Data = this.selectItem.datas.map((item, index) => {
      //   return [data[index], item.partSpeed.toFixed(2) + ''];
      // });
      let option = {
        title: {
          text: "回转角度曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        // toolbox: {
        //   top: "3%",
        //   right: "1%",
        //   feature: {
        //     saveAsImage: {}
        //   },
        //   showTitle: false
        // },
        legend: {
          top: "5%",
          right: "3%",
          data: ["回转角度"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "回转角度",
            type: "value",
            // inverse: true,
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          }
        ],
        series: [
          {
            name: "回转角度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart05 = echarts.init(this.$refs.myChart05, null, { devicePixelRatio: 2 });
      // 绘制图表
      this.myChart05.setOption(option);
      this.echart.push(this.myChart05)
    },
    // 详情弹出框标签切换
    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index === 1) {
        this.$nextTick(() => {
          this.initEchart03();
          // echarts.connect(this.echart)
          window.onresize = function () {
            self.myChart03.resize();
          };
        });
      }
      if (index === 2) {
        this.$nextTick(() => {
          this.initEchart01();
          this.initEchart02();
          this.initEchart04();
          this.initEchart05();
          echarts.connect(this.echart)
          window.onresize = function () {
            self.myChart01.resize();
            self.myChart02.resize();
            self.myChart04.resize();
            self.myChart05.resize();
          };
        });
      }
    },
    // 详情点击
    handleCommand(index) {
      this.centerDialogVisible = true;
      // 数据暂存在selectItem中
      this.selectItem = this.tableData[index];
      // console.log(this.selectItem);
      // 成槽时间
      this.selectItem.pileTime = Number(((this.selectItem.endTime - this.selectItem.beginTime) / 1000).toFixed(0))
      // 桩顶高程
      this.selectItem.pileTopelevation = this.selectItem.altitude - this.selectItem.designAntLength
      // 桩底高程
      this.selectItem.pileBottomElevation = this.selectItem.altitude - this.selectItem.designAntLength - this.selectItem.maxDepth
      // 方量
      // console.log(this.selectItem.workWidth, this.selectItem.designClawWidth,
      //   this.selectItem.maxDepth, this.selectItem.designSinkWidth);
      if (this.selectItem.workWidth == undefined) {
        this.selectItem.workWidth = 0
      }
      if (this.selectItem.designClawWidth == undefined) {
        this.selectItem.designClawWidth = 0
      }
      this.selectItem.pileSquare = (this.selectItem.workWidth + this.selectItem.designClawWidth) * this.selectItem.maxDepth * 0.4
      this.selectItem.pileSquarem2 = (this.selectItem.workWidth + this.selectItem.designClawWidth) * this.selectItem.maxDepth
      // this.selectItem.designSinkWidth
      var day = this.selectItem.beginTime
      var time = moment(this.selectItem.beginTime).format('YYYY-MM-DD HH:mm:ss')

      let PartData = []
      let Xdata = []
      let Ydata = []
      for (var i = 0; i < this.tableData[index].datas.length; i++) {
        if (this.tableData[index].datas[i].partTime == 0) {
          this.tableData[index].datas[i].partTime = this.tableData[index].datas[i].partTime + 1
        }
        this.tableData[index].datas[i].startDay = moment(day).format('HH:mm:ss')
        this.echartsdata.push(time)
        day += (this.tableData[index].datas[i].partTime * 1000)
        time = moment(day).format('YYYY-MM-DD HH:mm:ss')
        if (this.tableData[index].datas[i].partDepth > 3 && this.tableData[index].datas[i].partX != 0 && this.tableData[index].datas[i].partY != 0) {
          PartData.push([Number(this.tableData[index].datas[i].partX.toFixed(2)), Number(this.tableData[index].datas[i].partY.toFixed(2)), Number(this.tableData[index].datas[i].partDepth.toFixed(2))])
          Xdata.push(this.tableData[index].datas[i].partX)
          Ydata.push(this.tableData[index].datas[i].partY)
        }
      }
      // let echData = PartData   //原始版数据
      // 添加RtkQF判断
      let RtkQf = []
      let RtkQF5 = []
      let NoRtkQf = []
      for (let i = 0; i < this.selectItem.datas.length; i++) {
        if (this.tableData[index].datas[i].partDepth > 3 && this.tableData[index].datas[i].partX != 0 && this.tableData[index].datas[i].partY != 0) {
          // 有partRtkQF值
          if (this.selectItem.datas[i].partRtkQF != undefined) {
            if (this.selectItem.datas[i].partRtkQF == 4) {
              RtkQf.push([Number((this.selectItem.datas[i].partX - Math.min(...Xdata)).toFixed(2)),
              Number((this.selectItem.datas[i].partY - Math.min(...Ydata)).toFixed(2)),
              -Number(this.selectItem.datas[i].partDepth.toFixed(2))])
            }
            if (this.selectItem.datas[i].partRtkQF == 5) {
              RtkQF5.push([Number((this.selectItem.datas[i].partX - Math.min(...Xdata)).toFixed(2)),
              Number((this.selectItem.datas[i].partY - Math.min(...Ydata)).toFixed(2)),
              -Number(this.selectItem.datas[i].partDepth.toFixed(2))])
            }
          }
          // 没有partRtkQF值
          if (this.selectItem.datas[i].partRtkQF == undefined) {
            NoRtkQf.push([Number((this.selectItem.datas[i].partX - Math.min(...Xdata)).toFixed(2)),
            Number((this.selectItem.datas[i].partY - Math.min(...Ydata)).toFixed(2)),
            -Number(this.selectItem.datas[i].partDepth.toFixed(2))])
          }
        }
      }
      // console.log('RtkQf=4数据', RtkQf);
      // console.log('RtkQf=5数据', RtkQF5);
      // console.log('RtkQf数据', NoRtkQf);
      //添加partRtkQF的判断
      let echData = []
      if (this.selectItem.mileage != undefined) {
        echData = RtkQf.concat(RtkQF5)
      }
      if (this.selectItem.mileage == undefined) {
        echData = NoRtkQf
      }
      this.PartDatas = echData

      let XYMaxMin = [Number(Math.max(...Xdata).toFixed(0)), Number(Math.min(...Xdata).toFixed(0)),
      Number(Math.max(...Ydata).toFixed(0)), Number(Math.min(...Ydata).toFixed(0))]
      let maxY = 0
      let minY = 0
      let maxX = 0
      let minX = 0
      if (RtkQf.length != 0 && RtkQF5.length == 0) {
        maxY = Math.ceil(Number(Math.max(...Ydata).toFixed(0)) - Number(Math.min(...Ydata).toFixed(0))) + 1
        minY = 0
        maxX = null
        minX = 0
      }
      if (RtkQF5.length != 0 || NoRtkQf.length != 0) {
        let Xcha = XYMaxMin[0] - XYMaxMin[1]
        let Ycha = XYMaxMin[2] - XYMaxMin[3]
        if (Xcha >= Ycha) {
          maxY = (Math.ceil(Ycha) + 1) * 10
          minY = (Math.ceil(Ycha) + 1) * (-10)
          maxX = Math.ceil(Xcha) + 1
          minX = 0
        }
        if (Xcha < Ycha) {
          maxY = Math.ceil(Ycha) + 1
          minY = 0
          maxX = (Math.ceil(Xcha) + 1) * 10
          minX = (Math.ceil(Xcha) + 1) * (-10)
        }
      }
      this.partXY = [maxX, minX, maxY, minY]


      // console.log(echData);

      // let XX = []
      // let YY = []
      // for (let i = 0; i < Xdata.length; i++) {
      //   XX.push(Number((Xdata[i] - Math.min(...Xdata)).toFixed(2)))
      // }
      // for (let i = 0; i < Ydata.length; i++) {
      //   YY.push(Number((Ydata[i] - Math.min(...Ydata)).toFixed(2)))
      // }
      // console.log('X轴', Math.max(...XX), Math.min(...XX));
      // console.log('Y轴', Math.max(...YY), Math.min(...YY));



      // let ecMap = new Map()
      // for (let i = 0; i < echData.length; i++) {
      //   if (ecMap.get((echData[i][0] + ',' + echData[i][1]) == undefined)) {
      //     let MAxDeep = echData[i][2]
      //     ecMap.set(echData[i][0] + echData[i][1], MAxDeep)
      //   } else {
      //     let Maxdeep = ecMap.get((echData[i][0] + ',' + echData[i][1]))
      //     let MaxDepth
      //     if (Maxdeep >= echData[i][2]) {
      //       MaxDepth = Maxdeep
      //     } else {
      //       MaxDepth = echData[i][2]
      //     }
      //     ecMap.set((echData[i][0] + ',' + echData[i][1]), MaxDepth)
      //   }
      // }
      // let xyzdata = []
      // ecMap.forEach((value, key) => {
      //   let X = (Number(key.split(',')[0]) - Number(Math.min(...Xdata).toFixed(2))).toFixed(2)
      //   let Y = (Number(key.split(',')[1]) - Number(Math.min(...Ydata).toFixed(2))).toFixed(2)
      //   xyzdata.push([X, Y, -value])
      // })
      // this.PartDatas = xyzdata
      // x y轴的最大最小值

    },
    // 导出
    exportAll() {
      tableToExcel('ARDreporthistory', 'RTK成槽历史')
    },
    // 选中发生变化
    selectionChange(e) {
      let rep = e
      for (let i = 0; i < rep.length; i++) {
        if (rep[i].maxDepth < rep[i].designPileLength) {
          rep[i].color = '#ff3333'
        } else {
          rep[i].color = '#000'
        }
        rep[i].pileTime = Number(((rep[i].endTime - rep[i].beginTime) / 1000).toFixed(0))
        rep[i].pileTopelevation = rep[i].altitude - rep[i].designAntLength
        rep[i].pileBottomElevation = rep[i].altitude - rep[i].designAntLength - rep[i].maxDepth
        if (rep[i].workWidth == undefined) { rep[i].workWidth = 0 }
        if (rep[i].designClawWidth == undefined) { rep[i].designClawWidth = 0 }
        rep[i].pileSquarem2 = (rep[i].workWidth + rep[i].designClawWidth) * rep[i].maxDepth
      }
      console.log(rep);
      this.ARDreportHistory = rep
      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },

  }
}
</script>
<style scoped>
/* .dialogHeight {
  height: 100%;
} */
>>> .dialogHeight .el-dialog__body {
  flex: 1;
  padding: 0 1.25rem 1.25rem;
  overflow-x: hidden;
  overflow-y: auto;
}
.dialogMap_header {
  display: flex;
}
.D3Echart {
  /* width: 60rem; */
  height: 50rem;
}
.tdtMap {
  width: 100%;
  height: 100%;
}

.mapDiv {
  width: 100%;
  height: 100%;
}

.m-filter .item.picker {
  width: 30.25rem;
}
/* .el-col-12 {
  width: 100%;
} */
.ul-detail li {
  width: 33%;
}
/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.3rem 0 0;
}
.ul-detail li:first-child {
  padding-left: 1.5rem;
}
.ul-detail .s2 .tit {
  min-width: 6em;
}
.ul-detail .s3 .tit {
  min-width: 6em;
}
.CopyStyle {
  float: left;
}
</style>
