<template>
  <div class="row-history">
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
                <el-button type="primary" @click="showData()">选择参数</el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item input">
                  <el-input class="input" placeholder="请输入桩号" v-model="pileDescribe"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <!-- 选择参数按钮 -->
      <el-main class="m-table-main">
        <div id="table-content">
          <el-table class="jbThead" :data="tableData" style="width: 100%" ref="table" row-key="id" align='center' @selection-change="selectionChange" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
            <el-table-column type="selection" align='center' fixed></el-table-column>
            <el-table-column v-for="(col,index) in cols" :key="index" :prop="col.prop" :label="col.label" :formatter="col.formatter" min-width='160px' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column fixed="right">
              <template slot-scope="scope">
                <div class="m-edit-btns">
                  <div class="item">
                    <a href="javascript:" class="btn" @click="handleCommand(scope.$index)">详情</a>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-main>
      <!-- 选择参数按钮弹出框 -->
      <el-dialog title="选择需要展示的参数" :visible.sync="dialogFormVisible" width="40%" center top="15vh">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedFileds" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in fieldNames" :label="item.prop" :key="item.prop">{{ item.label }}</el-checkbox>
        </el-checkbox-group>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="medium">取 消</el-button>
          <el-button type="primary" @click="confirmShow" size="medium">确定</el-button>
        </div>
      </el-dialog>

    </el-container>
    <!-- 详情弹出框 -->
    <el-dialog :visible.sync="centerDialogVisible" class="m-pop m-pop-detail" v-el-dialog-drag>
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
        <!-- <div class="right">
          <el-dropdown placement="top-start" @command="downLoad">
            <span class="more ">报表下载
            </span>
            <el-dropdown-menu class="g-dropdown">
              <a id="simulation" href=""></a>
              <el-dropdown-item :command="0">
                <span class="icon icon01"></span>
                <span class="name">打印版</span>
              </el-dropdown-item>
              <el-dropdown-item :command="1">
                <span class="icon icon02"></span>
                <span class="name">综合版</span>
              </el-dropdown-item>
              <el-dropdown-item :command="2">
                <span class="icon icon03"></span>
                <span class="name">详细版</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div> -->
      </div>
      <!-- 详细数据 -->
      <div class="dialog-content">
        <div class="m-tab-table m-tab-detail" v-show="tab2 === 0">
          <div class="m-table-detail">
            <!-- 详细数据 -->
            <div class="detailitem">
              <h3>详细数据</h3>
              <div class="box">
                <ul class="ul-detail">
                  <li class="s1">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">桩号</div>
                        <div class="value valuepile" :title=this.selectItem.pileDescribe>{{selectItem.pileDescribe}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">设备号</div>
                        <div class="value">{{selectItem.deviceKey}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">开始时间</div>
                        <div class="value">{{selectItem.beginTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">结束时间</div>
                        <div class="value">{{selectItem.endTime|datafilters}}</div>
                      </div>

                    </div>
                  </li>
                  <li class="s2">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">桩唯一标识</div>
                        <div class="value">{{selectItem.pileId}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">垂直度(%)</div>
                        <div class="value">{{selectItem.angle|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">经度坐标</div>
                        <div class="value">{{selectItem.longitude}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">维度坐标</div>
                        <div class="value">{{selectItem.latitude}}</div>
                      </div>

                    </div>
                  </li>
                  <li class="s3">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">钻入深度</div>
                        <div class="value">{{selectItem.depth|numfilters}}(m)</div>
                      </div>
                      <div class="item">
                        <div class="tit">终孔转速</div>
                        <div class="value">{{selectItem.rpm|numfilters(1)}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">平均转速</div>
                        <div class="value">{{selectItem.avgRpm|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">总时长(s)</div>
                        <div class="value">{{selectItem.pileTime}}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 段数据 -->
            <br />
            <h3>段数据列表</h3>
            <el-table ref="saveTable" class="m-table-save m_table_save_height" :data="selectItem.data" style="width: 100%;margin-top:20px" height="28em">
              <el-table-column property="order" label="序号" min-width="4.33%" align='center'>
                <!-- <template slot-scope="scope" >{{ scope.$index + 1 }}</template> -->
              </el-table-column>
              <el-table-column property="depth" label="深度值(m)" min-width="8.83%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.depth|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="rotateSpeed" label="转速" min-width="8.83%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.rotateSpeed|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="realTime" label="上传时间" min-width="8.83%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.realTime|datafilters }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 图表曲线 -->
        <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 1">
          <div class="m-pop-echart">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-footer class="m-selectAll">
      <!-- <div class="left">
        <div class="item">
          <el-checkbox v-model="totalChecked" class="total" @change="totalChange">全选
          </el-checkbox>
        </div>
        <div class="item">
          <div class="export" id="excelOut" @click="exportAll">导出</div>
          <a href="javascript:void(0);" v-on:click="exportAll()" class="export">导出</a> 
        </div>
      </div> -->
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>

        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot, jumper, sizes" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import { http } from '../../main'
import moment from "moment";
import { historyList } from "@/api/api"
import gTabs from "@/components/gTabs";
import * as echarts from "echarts";
const allField = ['pileDescribe', 'deviceKey', 'beginTime', 'endTime', 'pileId', 'depth', 'angle', 'longitude', 'latitude', 'rpm', 'avgRpm', 'pileTime'] //所有的数据
export default {
  components: {
    gTabs
  },
  props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'handleCurrentChange', 'toLastPage', 'toFirstPage', 'pileDescribe'],
  data() {
    return {
      report: 'report',
      checkedData: [],
      totalChecked: false,
      startDate: '',
      endDate: '',
      number: "",
      deviceKey: '',
      checkedId: [], //存储点击后导出的id
      checkAll: false,  //默认全选
      checkedFileds: ['pileDescribe', 'deviceKey', 'beginTime', 'endTime', 'pileId', 'depth', 'angle', 'longitude', 'latitude', 'rpm', 'avgRpm', 'pileTime'], //默认选中要显示的数据
      fieldNames: [{
        prop: 'pileDescribe',
        label: '桩号'
      },
      {
        prop: 'deviceKey',
        label: '设备编号'
      },
      {
        prop: 'beginTime',
        label: '开始时间',
        formatter: this.testFormatter,
      },
      {
        prop: 'endTime',
        label: '结束时间',
        formatter: this.testFormatter,
      },
      {
        prop: 'pileId',
        label: '桩唯一标识',
      },
      {
        prop: 'depth',
        label: '钻入深度(m)',
        formatter: this.numFormatter
      },
      {
        prop: 'angle',
        label: '垂直度(%)',
        formatter: this.numFormatter
      },
      {
        prop: 'longitude',
        label: '经度坐标',
      },
      {
        prop: 'latitude',
        label: '纬度坐标',
      },
      {
        prop: 'rpm',
        label: '终孔转速',
        formatter: this.numsFormatter
      },
      {
        prop: 'avgRpm',
        label: '平均转速',
        formatter: this.numFormatter
      },
      {
        prop: 'pileTime',
        label: '总时长(s)'
      }],   //对象数组全部的数据
      isIndeterminate: false,   //默认全选
      dialogFormVisible: false,
      cols: [
        {
          prop: 'pileDescribe',
          label: '桩号'
        },
        {
          prop: 'deviceKey',
          label: '设备编号'
        },
        {
          prop: 'beginTime',
          label: '开始时间',
          formatter: this.testFormatter,
        },
        {
          prop: 'endTime',
          label: '结束时间',
          formatter: this.testFormatter,
        },
        {
          prop: 'pileId',
          label: '桩唯一标识',
        },
        {
          prop: 'depth',
          label: '钻入深度(m)',
          formatter: this.numFormatter
        },
        {
          prop: 'angle',
          label: '垂直度(%)',
          formatter: this.numFormatter
        },
        {
          prop: 'longitude',
          label: '经度坐标',
        },
        {
          prop: 'latitude',
          label: '纬度坐标',
        },
        {
          prop: 'rpm',
          label: '终孔转速',
          formatter: this.numsFormatter
        },
        {
          prop: 'avgRpm',
          label: '平均转速',
          formatter: this.numFormatter
        },
        {
          prop: 'pileTime',
          label: '总时长(s)'
        }
      ],
      tempCols: [],  //临时的数据容器
      // 弹窗
      centerDialogVisible: false,
      data: [],
      selectItem: [],
      tab2: 0,
      tab2List: [
        {
          name: "详细内容",
        },
        {
          name: "图表曲线",
        },
      ],
      totalNum: 0,
      inquery: []
    }
  },
  filters: {
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    minfilters(val) {
      return (parseFloat(val) / 60).toFixed(2)
    },
    numfilters(val, deg) {
      if (deg == undefined) {
        deg = 2
      }
      if (isNaN(val)) {
        //val 不是数字
        return val
      } else {
        //val是数字
        return parseFloat(val).toFixed(deg)
      }
    },
  },
  watch: {
    centerDialogVisible(val, oldval) {
      window.onresize = null
      if (val == false) {
        this.tab2 = 0
        this.selectItem = []
        this.data = []
        if (this.myChart01 === undefined || this.myChart01._disposed) return
        this.myChart01.dispose()
      }
    }
  },
  mounted() {
    this.deviceKey = this.device
  },
  methods: {
    // 设备查询
    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //选择设备查询
      //将修改的key值传到主页面
      //在主页面改为传过来的值
      //在主页面写一个方法接受传过来的值
      this.$emit('changeDevicekey', this.deviceKey, this.pileDescribe, this.startDate, this.endDate)
      historyList('XWZ', 0, this.device, this.pageSize, this.pileDescribe, this.startDate, this.endDate).then((res) => {
        this.inquery = res.content;
        this.totalNum = res.totalElements;
        this.$emit('changeDevicekey', this.deviceKey, this.pileDescribe, this.startDate, this.endDate, this.inquery, this.totalNum)
        loading.close();
      });
      setTimeout(() => {
        loading.close();
      }, 6000);
    },
    resetClick() {
      this.deviceKey = ""
      this.startDate = ''
      this.endDate = ''
      this.number = ''
    },

    testFormatter(row, column, cellValue, index) {
      return moment(cellValue * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    minFormatter(row, column, cellValue, index) {
      return (parseFloat(cellValue) / 60).toFixed(0)
    },
    numFormatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(2)
    },
    numsFormatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(1)
    },
    //全选按钮 选择参数显示数据
    handleCheckAllChange(val) {
      this.checkedFileds = val ? allField : [];
      this.isIndeterminate = false;
    },
    //单个选中
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fieldNames.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fieldNames.length;
    },
    showData(row) {
      this.dialogFormVisible = true;
    },
    confirmShow() {
      this.tempCols = [];
      if (this.checkedFileds.length < 1) {
        this.$message.warning("请至少选择一项数据");
        this.dialogFormVisible = true;
      } else {
        for (var i = 0; i < this.fieldNames.length; i++) {
          for (var j = 0; j < this.checkedFileds.length; j++) {
            if (this.fieldNames[i].prop == this.checkedFileds[j]) {
              this.tempCols.push(this.fieldNames[i]);
            }
          }
        }
        this.cols = this.tempCols;
        this.dialogFormVisible = false;
      }
    },
    initEchart01() {
      let data = this.data;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.depth];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.rotateSpeed.toFixed(3) + ''];
      });
      let option = {
        title: {
          text: "深度、转速曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "转速"],
        },
        grid: {
          top: "23%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10
          }
        },
        yAxis: [
          {
            name: "深度(m)  ",
            type: "value",
            inverse: true,
            nameLocation: "end",
            axisLine: { //设置轴线
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: " 转速",
            nameLocation: "end", // 设置坐标轴名称显示位置
            inverse: true,
            type: "value",
            splitLine: { //网格不显示
              show: false
            },
            nameTextStyle: {
              padding: [10, 20, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "转速",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart01.setOption(option);
    },
    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index === 1) {
        this.$nextTick(() => {
          this.initEchart01();
          window.onresize = function () {
            self.myChart01.resize();
          };
        });
      }
    },
    // 点击详情 详情弹出框
    handleCommand(index) {
      this.selectItem = this.tableData[index]
      // 显示弹出框
      this.centerDialogVisible = true;
      for (var i = 0; i < this.tableData[index].data.length; i++) {
        this.data.push(moment(this.tableData[index].data[i].realTime * 1000).format('YYYY-MM-DD HH:mm:ss'))
      }
    },
    // 选中发生变化 全选选择框 全选列表导出
    selectionChange(e) {
      this.checkedData = e
      this.checkedId = []
      for (var i = 0; i < e.length; i++) {
        this.checkedId.push(e[i]._id)
      }
      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },
  }
}
</script>
<style scoped>
.el-col-12 {
  width: 100%;
}
.el-button--primary {
  margin-left: 25px;
  margin-right: 5px;
}
.el-checkbox {
  width: 20%;
}
.more {
  margin-left: 1.75rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAYAAAB836/YAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MUQ5MUJCNkEzRkYxMUVCQTE1NUVDNDc5MDNFOTc4OSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MUQ5MUJCN0EzRkYxMUVCQTE1NUVDNDc5MDNFOTc4OSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjgxRDkxQkI0QTNGRjExRUJBMTU1RUM0NzkwM0U5Nzg5IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjgxRDkxQkI1QTNGRjExRUJBMTU1RUM0NzkwM0U5Nzg5Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+B0oTKQAAAE9JREFUeNpi/P//PwM1ARMDlcGogUPUQE8gfgbFnoQUMxKRDkEGSULZz4FYippeZqSGl1OA+AUQPwXiZGp4eTQdjibs0YQ9mg5HgoEAAQYAMKsdCdjHQ/QAAAAASUVORK5CYII=)
    center center no-repeat;
  background-size: contain;
  float: left;
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  overflow: hidden;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  cursor: pointer;
}

.jbThead {
  font-size: 14px;
  color: #515a6e;
}

.ul-detail .inner {
  padding: 0.625rem 0 0.625rem 0.3rem;
}
.ul-detail .s3 .tit {
  min-width: 10em;
}
.ul-detail .s1 .tit {
  margin-right: 0;
  min-width: 5em;
}
.valuepile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.3rem 0 0;
  width: 33%;
}
.ul-detail li:first-child {
  padding-left: 1.3rem;
}
</style>

