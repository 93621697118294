<template>
  <div class="row-history">
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>

                <div class="item">至</div>

                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>

                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <el-main>
        <el-table :data="tableData" style="width: 100%" ref="table" row-key="id" @selection-change="selectionChange" :cell-style="tableCellclassname" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
          <el-table-column fixed type="selection" align="center"></el-table-column>
          <el-table-column fixed prop="deviceKey" label="机器编号" min-width='120px' align='center' show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="beginTime" label="开始时间" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.beginTime|datafilters }}
            </template>
          </el-table-column>
          <el-table-column prop="endTime" label="结束时间" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.endTime|datafilters }}
            </template>
          </el-table-column>
          <el-table-column prop="water" label="加水量(Kg)" min-width='90px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.water|numfilters }}
            </template>
          </el-table-column>
          <el-table-column prop="ash" label="加灰量(kg)" min-width='90px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.ash|numfilters }}
            </template>
          </el-table-column>
          <el-table-column prop="waterCementRatio" label="水灰比" min-width='90px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.waterCementRatio|numfilters }}
            </template>
          </el-table-column>
          <el-table-column prop="endTime" label="持续时间(min)" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row|minfilters }}
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
    <el-footer class="m-selectAll">
      <div class="left">
        <div class="item">
          <el-checkbox v-model="totalChecked" class="total" @change="totalChange">全选
          </el-checkbox>
        </div>
        <div class="item">
          <a href="javascript:;" class="export">导出</a>
        </div>
      </div>
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot, sizes, prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot, jumper, sizes" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import moment from "moment";
import { historyList } from "@/api/api"
export default {
  props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'handleCurrentChange', 'toLastPage', 'toFirstPage', 'pileDescribe'],
  data() {
    return {
      totalChecked: false,
      startDate: '',
      endDate: '',
      number: "",
      deviceKey: '',
      totalNum: 0,
      inquire: []
    }
  },
  filters: {
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val) {
      return parseFloat(val).toFixed(2)
    },
    minfilters(row) {
      return (parseFloat(row.endTime - row.beginTime) / 60).toFixed(1)
    }
  },
  mounted() {
    this.deviceKey = this.device
  },
  methods: {
    tableCellclassname({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1 || columnIndex == 2 || columnIndex == 3) {
        return { textAlign: 'center' }
      } else {
        return { textAlign: 'centert' }
      }
    },
    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //选择设备查询
      //将修改的key值传到主页面
      //在主页面改为传过来的值
      //在主页面写一个方法接受传过来的值
      historyList('HTZJ', 0, this.deviceKey, this.pageSize, this.number, this.startDate, this.endDate).then((res) => {
        this.inquire = res.content;
        this.totalNum = res.totalElements;
        this.$emit('changeDevicekey', this.deviceKey, '', this.startDate, this.endDate, this.inquire, this.totalNum)
        loading.close();
      });
      setTimeout(() => {
        loading.close();
      }, 6000);
    },
    resetClick() {
      this.deviceKey = ""
      this.startDate = ''
      this.endDate = ''
    },

    // 选中发生变化
    selectionChange(e) {
      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },
  }
}
</script>
