<template>
  <div class="row-history">
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
                <!-- <el-button type="primary" @click="showData()">选择参数</el-button> -->
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item input">
                  <el-input class="input" placeholder="请输入桩号" v-model="number"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <el-main class="m-table-main">
        <div id="table-content">
          <el-table :data="tableData" style="width: 100%" ref="table" row-key="id" @selection-change="selectionChange" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
            <el-table-column type="selection" align='center' fixed></el-table-column>
            <el-table-column :width="tableWidth(index)" v-for="(col,index) in cols" :key="index" :prop="col.prop" :label="col.label" :formatter="col.formatter" min-width='153px' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column fixed="right">
              <template slot-scope="scope">
                <div class="m-edit-btns">
                  <div class="item">
                    <a href="javascript:" class="btn" @click="handleCommand(scope.$index)">详情</a>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-main>
      <el-dialog title="选择需要展示的参数" :visible.sync="dialogFormVisible" width="40%" center top="15vh">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
        </el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedFileds" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in fieldNames" :label="item.prop" :key="item.prop">{{
                            item.label
                        }}
          </el-checkbox>
        </el-checkbox-group>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="medium">取 消</el-button>
          <el-button type="primary" @click="confirmShow" size="medium">确定</el-button>
        </div>
      </el-dialog>
    </el-container>
    <!-- 弹出框 -->
    <el-dialog :visible.sync="centerDialogVisible" class="m-pop m-pop-detail">
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
        <div class="right">
          <el-button v-if="showbtn" type="primary" round size="small" @click="download">图片下载</el-button>
        </div>
      </div>
      <div class="dialog-content">
        <div class="m-tab-table m-tab-detail" v-show="tab2 === 0">
          <div class="m-table-detail">
            <div class="detailitem">
              <h3>详细数据</h3>
              <div class="box">
                <ul class="ul-detail">
                  <li class="s1">
                    <div class="inner liinner">
                      <div class="item">
                        <div class="tit">桩号</div>
                        <div class="value">{{ selectItem.pileDescribe }}</div>
                      </div>
                      <div class="item">
                        <div class="tit">桩机编号</div>
                        <div class="value">{{ selectItem.deviceKey }}</div>
                      </div>
                      <div class="item">
                        <div class="tit">施工桩长</div>
                        <div class="value">{{ selectItem.depth|numfilters }}{{"\t"}}m</div>
                      </div>
                      <div class="item">
                        <div class="tit">最大斜度</div>
                        <div class="value">{{ selectItem.maxAngle|numfilters }}{{"\t"}} %</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计总量</div>
                        <div class="value">{{ selectItem.totalWeight|numfilters(0) }} kg</div>
                      </div>
                    </div>
                  </li>
                  <li class="s2">
                    <div class="inner liinner">
                      <div class="item">
                        <div class="tit">开始时间</div>
                        <div class="value">{{ selectItem.beginTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">起拔时间</div>
                        <div class="value">{{ selectItem.beginUpTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">结束时间</div>
                        <div class="value">{{ selectItem.endTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">起拔时长</div>
                        <div class="value">{{ selectItem.upTime|minfilters }}{{"\t"}}min</div>
                      </div>
                      <div class="item">
                        <div class="tit">成桩时间</div>
                        <div class="value">{{ selectItem.pileTime|minfilters }}{{"\t"}}min</div>
                      </div>
                    </div>
                  </li>
                  <li class="s3">
                    <div class="inner liinner">
                      <div class="item">
                        <div class="tit">钻孔速度</div>
                        <div class="value">{{ selectItem.downSpeed|numfilters}}{{"\t"}}cm/min</div>
                      </div>
                      <div class="item">
                        <div class="tit">起拔速度</div>
                        <div class="value">{{ selectItem.upSpeed|numfilters}}{{"\t"}}cm/min</div>
                      </div>
                      <div class="item">
                        <div class="tit">持力电流</div>
                        <div class="value">{{ selectItem.pileTopCurrent|numfilters(1)}}{{"\t"}}A</div>
                      </div>
                      <div class="item">
                        <div class="tit">成孔电流</div>
                        <div class="value">{{ selectItem.avgCurrent|numfilters(1) }}{{"\t"}}A</div>
                      </div>
                      <div class="item">
                        <div class="tit">持力层(比值)</div>
                        <div class="value">{{ selectItem.pileTopCurrentRatio|numfilters}}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <br />
            <h3>段数据列表</h3>
            <el-table ref="saveTable" class="m-table-save m_table_save_height" :data="selectItem.data" style="width: 100%;margin-top:20px" height="28em">
              <el-table-column property="startDay" label="时间" min-width="8.33%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.startDay|timefilter }}
                </template>
              </el-table-column>
              <el-table-column property="partDeep" label="段深度(m)" min-width="8.33%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partDeep|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partCurrent1" label="电流(A)" min-width="8.33%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partCurrent1|numfilters(1) }}
                </template>
              </el-table-column>
              <el-table-column property="partDownSpeed" label="速度(cm/min)" min-width="8.33%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partDownSpeed|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="angle" label="垂直度(%)" min-width="8.33%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.angle|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partTime" label="段时长(s)" min-width="8.33%" align="center">
              </el-table-column>
              <el-table-column property="partDownSpeed" label="状态" min-width="8.33%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partDownSpeed|num3filters }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 图表曲线 -->
        <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 1">
          <div class="m-pop-echart">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart04" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart02" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart03" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="echartdownLoad">
            <div ref="myChart06" :style="{ width: '100%', height: '33.3%' }"></div>
            <div ref="myChart07" :style="{ width: '100%', height: '33.3%' }"></div>
            <div ref="myChart08" :style="{ width: '100%', height: '33.3%' }"></div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-footer class="m-selectAll">
      <div class="left">
        <div class="item">
          <el-checkbox v-model="totalChecked" class="total" @change="totalChange">全选
          </el-checkbox>
        </div>
        <div class="item">
          <div class="export" @click="exportexcel">导出</div>
        </div>
      </div>
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,sizes, jumper" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import moment from "moment";
import { historyList } from "@/api/api"
import gTabs from "@/components/gTabs";
import * as echarts from "echarts";

const allField = ['pileDescribe', 'deviceKey', 'beginTime', 'endTime', 'depth', 'avgCurrent', 'pileTopCurrent', 'pileTopCurrentRatio', 'maxAngle', 'pileTime',
  'cumulativePulp', 'downSpeed', 'upSpeed', 'maxUpSpeed', 'longitude', 'latitude', 'emDepth']; //所有的数据
export default {
  components: {
    gTabs,
  },
  props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'handleCurrentChange', 'toLastPage', 'toFirstPage', 'pileDescribe'],
  data() {
    return {
      totalChecked: false,
      startDate: '',
      endDate: '',
      number: "",
      deviceKey: '',
      checkAll: false,  //默认全选
      checkedFileds: ['pileDescribe', 'deviceKey', 'beginTime', 'endTime', 'depth', 'avgCurrent', 'pileTopCurrent', 'pileTopCurrentRatio', 'maxAngle', 'pileTime'], //默认选中的数据
      fieldNames: [{
        prop: 'pileDescribe',
        label: '桩号'
      },
      {
        prop: 'deviceKey',
        label: '桩机号'
      },
      {
        prop: 'beginTime',
        label: '开始时间',
        formatter: this.testFormatter,
      },
      {
        prop: 'endTime',
        label: '结束时间',
        formatter: this.testFormatter,
      },
      {
        prop: 'depth',
        label: '成桩深度(m)',
        formatter: this.numFormatter
      },
      {
        prop: 'avgCurrent',
        label: '成孔电流(A)',
        formatter: this.numFormatter
      },
      {
        prop: 'pileTopCurrent',
        label: '持力电流(A)',
        formatter: this.numFormatter
      },
      {
        prop: 'pileTopCurrentRatio',
        label: '持力层(比值)',
        formatter: this.numFormatter
      },
      {
        prop: 'maxAngle',
        label: '垂直度(%)',
        formatter: this.num1Formatter
      },
      {
        prop: 'pileTime',
        label: '成桩时间(min)',
        formatter: this.minFormatter
      },
      {
        prop: 'cumulativePulp',
        label: '注入量(m³)',
        formatter: this.PulpFormatter
      },

      {
        prop: 'downSpeed',
        label: '钻孔速度(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'upSpeed',
        label: '起拔速度(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'maxUpSpeed',
        label: '最大提速(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'longitude',
        label: '经度'
      },
      {
        prop: 'latitude',
        label: '纬度'
      },

      {
        prop: 'emDepth',
        label: '空搅深度(m)',
        formatter: this.numFormatter
      },
      ],   //对象数组全部的数据
      isIndeterminate: false,   //默认全选
      dialogFormVisible: false,
      cols: [
        {
          prop: 'pileDescribe',
          label: '桩号'
        },
        {
          prop: 'deviceKey',
          label: '桩机号'
        },

        {
          prop: 'beginTime',
          label: '开始时间',
          formatter: this.testFormatter,
        },
        {
          prop: 'endTime',
          label: '结束时间',
          formatter: this.testFormatter,
        },
        {
          prop: 'depth',
          label: '成桩深度(m)',
          formatter: this.numFormatter
        },
        {
          prop: 'avgCurrent',
          label: '成孔电流(A)',
          formatter: this.numFormatter
        },
        {
          prop: 'pileTopCurrent',
          label: '持力电流(A)',
          formatter: this.numFormatter
        },
        {
          prop: 'pileTopCurrentRatio',
          label: '持力层(比值)',
          formatter: this.numFormatter
        },
        {
          prop: 'maxAngle',
          label: '垂直度(%)',
          formatter: this.num1Formatter
        },
        {
          prop: 'pileTime',
          label: '成桩时间(min)',
          formatter: this.minFormatter
        },
      ],
      tempCols: [],  //临时的数据容器
      // 弹窗
      centerDialogVisible: false,
      echartsdata: [],
      selectItem: [],
      tab2: 0,
      tab2List: [
        {
          name: "详细内容",
        },
        {
          name: "图表曲线",
        }
      ],
      selectTable: "记录报表下载",
      selectTableList: [
        {
          label: "记录报表下载",
          value: "0",
        },
        {
          label: "记录报表下载1",
          value: "1",
        },
      ],
      inquire: '',
      totalNum: '',
      selectAll: [],
      echart: [],
      echartload: [],
      showbtn: false
    }
  },
  filters: {
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    timefilter(val) {
      return moment(val * 1000).format('HH:mm:ss')
    },
    numfilters(val, par) {
      if (par == undefined) {
        par = 2
      }
      if (isNaN(val)) {
        return val
      } else {
        return parseFloat(val).toFixed(par)
      }
    },
    pulpfilters(val) {
      if (val.cumulativePulp == 0) {
        if (val.theoreticalCumulativePulp == undefined) {
          return '-'
        } else {
          return parseFloat(val.theoreticalCumulativePulp).toFixed(2)
        }
      } else {
        return parseFloat(val.cumulativePulp).toFixed(2)
      }
    },
    minfilters(val) {
      return parseFloat(val / 60).toFixed(0)
    },
    num3filters(val) {
      if (val == 0) {
        val = '停钻'
      }
      if (val < 0) {
        val = '起拔'
      }
      if (val > 0) {
        val = '钻孔'
      }
      return val
    }
  },
  watch: {
    centerDialogVisible(val, oldval) {
      if (val == false) {
        this.showbtn = false
        this.tab2 = 0
        this.selectItem = []
        this.echartsdata = []
        if (this.myChart01 == undefined || this.myChart01._disposed) return
        this.myChart01.dispose()
        this.myChart02.dispose()
        this.myChart03.dispose()
        this.myChart04.dispose()
        this.myChart06.dispose()
        this.myChart07.dispose()
        this.myChart08.dispose()
      }
    }
  },
  mounted() {
    this.deviceKey = this.device
  },
  methods: {
    download() {
      let a = document.createElement('a');
      a.href = this.myChart06.getConnectedDataURL({
        pixelRatio: 2,
        backgroundColor: '#fff'
      })
      a.download = "图片下载.png";
      a.click();
    },
    // 设置table表格宽度
    tableWidth(index) {
      if (index == 4 || index == 5 || index == 6 || index == 7 || index == 8 || index == 9) {
        return 120
      }
    },
    exportexcel() {
      // this.tableToExcel('CFGStatisticsTabledetailed', 'CFG桩-导出')
    },
    // 查询
    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      })
      historyList('CGSZ', 0, this.deviceKey, this.pageSize, this.number, this.startDate, this.endDate).then((res) => {
        this.inquire = res.content;
        this.totalNum = res.totalElements;
        this.$emit('changeDevicekey', this.deviceKey, this.number, this.startDate, this.endDate, this.inquire, this.totalNum)
        loading.close()
      });
      setTimeout(() => {
        loading.close()
      }, 6000)
    },
    resetClick() {
      this.deviceKey = ""
      this.startDate = ''
      this.endDate = ''
      this.number = ""
    },

    testFormatter(row, column, cellValue, index) {
      return moment(cellValue * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numFormatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(2)
    },
    minFormatter(row, column, cellValue, index) {
      return (parseFloat(cellValue) / 60).toFixed(0)
    },
    PulpFormatter(row, column, cellValue, index) {
      return (parseFloat(cellValue) / 1000).toFixed(2)
    },
    num1Formatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(2) + '%'
    },

    //全选按钮
    handleCheckAllChange(val) {
      this.checkedFileds = val ? allField : [];
      this.isIndeterminate = false;
    },
    //单个选中
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fieldNames.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fieldNames.length;
    },
    showData(row) {
      this.dialogFormVisible = true;
    },
    confirmShow() {
      this.tempCols = [];
      if (this.checkedFileds.length < 1) {
        this.$message.warning("请至少选择一项数据");
        this.dialogFormVisible = true;
      } else {
        for (var i = 0; i < this.fieldNames.length; i++) {
          for (var j = 0; j < this.checkedFileds.length; j++) {
            if (this.fieldNames[i].prop == this.checkedFileds[j]) {
              this.tempCols.push(this.fieldNames[i]);
            }
          }
        }
        this.cols = this.tempCols;
        this.dialogFormVisible = false;
      }
    },
    initEchart04() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partDeep.toFixed(2))];
      });
      let option = {
        title: {
          text: "深度",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度          ",
            inverse: true,
            nameLocation: "end",
            type: "value",
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            axisLine: {
              show: true
            },
            max: 30
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart04 = echarts.init(this.$refs.myChart04, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart04.setOption(option);
      this.echart.push(this.myChart04)
    },
    initEchart01() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partDeep).toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partCurrent1).toFixed(2)];
      });
      let option = {
        title: {
          text: "深度、电流曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
          selectedMode: true
        },
        legend: {
          top: "5%",
          right: "10%",
          // data: ["深度", "电流"],
          data: [
            { name: "深度" },
            { name: "电流" }
          ],
        },

        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "电流          ",
            // inverse: true,
            nameLocation: "start",
            type: "value",
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            axisLine: {
              show: true
            },
            max: 300
          },
          {
            name: "深度",
            nameLocation: "end", // 设置坐标轴名称显示位置
            inverse: true,
            type: "value",
            max: 30,
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
            splitLine: {
              show: false
            }
          },
        ],
        series: [
          {
            name: "深度",
            yAxisIndex: 1,
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "电流",

            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart01.setOption(option);
      this.echart.push(this.myChart01)
    },
    initEchart02() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partDeep).toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.totalWeight).toFixed(2)];
      });
      let option = {
        title: {
          text: "深度、填料曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
          selectedMode: true
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "填料"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "填料",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            // max: 70,
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
            axisLine: {
              show: true
            }
          },
          {
            name: "深度          ",
            inverse: true,
            nameLocation: "end", // 设置坐标轴名称显示位置
            type: "value",
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            splitLine: {
              show: false
            }
          },
        ],
        dataZoom: [
          // 区域缩放
          {
            type: 'inside',
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
          }
        ],
        series: [
          {
            name: "深度",
            yAxisIndex: 1,
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "填料",
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart02 = echarts.init(this.$refs.myChart02, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart02.setOption(option);
      this.echart.push(this.myChart02)
    },
    initEchart03() {
      var ash = []
      //   for (var k = 0; k < this.selectItem.data.length; k++) {
      //     if (ash[Math.ceil(this.selectItem.data[k].partDeep - 1)] == undefined) {
      //       ash[Math.ceil(this.selectItem.data[k].partDeep - 1)] = 0
      //     }
      //     ash[Math.ceil(this.selectItem.data[k].partDeep - 1)] = 1 + Math.random() / 20
      //   }
      for (var k = 1; k <= Math.ceil(this.selectItem.depth); k++) {
        // if (ash[Math.ceil(this.selectItem.data[k].partDeep - 1)] == undefined) {
        //   ash[Math.ceil(this.selectItem.data[k].partDeep - 1)] = 0
        // }
        ash.push(1 + Math.random() / 20)
      }
      //   var numbers2 = ash.map(myFunction);
      //   function myFunction(value, index, array) {
      //     var data = {
      //       value: Number(value.toFixed(2)),
      //       //   name: index + 1
      //     }
      //     return data;
      //   }

      //double d = 2 * Math.sqrt(Long.parseLong(history.get("totalWeight").toString()) / 1500.00 * 0.9 / 3.14 / history.getDouble("depth"));
      var diameter = (2 * Math.sqrt(this.selectItem.totalWeight.toString() / 1500.00 * 0.9 / 3.14 / this.selectItem.depth)).toFixed(2)
      let option = {
        title: {
          text: "成桩效果图(理论桩径：" + diameter + "m)",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} : {c}",
          show: false
        },
        grid: {
          top: "10%",
          bottom: "10%",
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        series: [
          {
            name: "",
            type: "funnel",
            width: "50%",
            left: "center",
            top: "15%",
            bottom: "8%",
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderWidth: 0,
              borderColor: '#5470c6',
              color: '#5470c6'
            },
            data: ash,
            sort: "none", //设置不排序
            color: '#5470c6',
            gap: 0  //设置两个块之间的距离
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart03 = echarts.init(this.$refs.myChart03, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart03.setOption(option);
    },
    initEchart06() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partDeep.toFixed(2))];
      });
      let option = {
        title: {
          text: "深度",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度          ",
            inverse: true,
            nameLocation: "end",
            type: "value",
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            axisLine: {
              show: true
            },
            max: 30
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart06 = echarts.init(this.$refs.myChart06, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart06.setOption(option);
      this.echartload.push(this.myChart06)
    },
    initEchart07() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partDeep).toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partCurrent1).toFixed(2)];
      });
      let option = {
        title: {
          text: "深度、电流曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
          selectedMode: true
        },
        legend: {
          top: "5%",
          right: "10%",
          // data: ["深度", "电流"],
          data: [
            { name: "深度" },
            { name: "电流" }
          ],
        },

        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "电流          ",
            // inverse: true,
            nameLocation: "start",
            type: "value",
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            axisLine: {
              show: true
            },
            max: 300
          },
          {
            name: "深度",
            nameLocation: "end", // 设置坐标轴名称显示位置
            inverse: true,
            type: "value",
            max: 30,
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
            splitLine: {
              show: false
            }
          },
        ],
        series: [
          {
            name: "深度",
            yAxisIndex: 1,
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "电流",

            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart07 = echarts.init(this.$refs.myChart07, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart07.setOption(option);
      this.echartload.push(this.myChart07)
    },
    initEchart08() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partDeep).toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.totalWeight).toFixed(2)];
      });
      let option = {
        title: {
          text: "深度、填料曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
          selectedMode: true
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "填料"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "填料",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            // max: 70,
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
            axisLine: {
              show: true
            }
          },
          {
            name: "深度          ",
            inverse: true,
            nameLocation: "end", // 设置坐标轴名称显示位置
            type: "value",
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            splitLine: {
              show: false
            }
          },
        ],
        series: [
          {
            name: "深度",
            yAxisIndex: 1,
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "填料",
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart08 = echarts.init(this.$refs.myChart08, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart08.setOption(option);
      this.echartload.push(this.myChart08)
    },

    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index == 0) {
        this.showbtn = false
      }
      if (index === 1) {
        this.showbtn = true
        this.$nextTick(() => {
          this.initEchart01();
          this.initEchart02();
          this.initEchart03();
          this.initEchart04();
          echarts.connect(this.echart)
          this.initEchart06();
          this.initEchart07();
          this.initEchart08();
          echarts.connect(this.echartload)
          window.onresize = function () {
            self.myChart01.resize();
            self.myChart02.resize();
            self.myChart03.resize();
            self.myChart04.resize();
          };
        });
      }
    },
    // 详情点击
    handleCommand(index) {
      this.selectItem = this.tableData[index];
      this.centerDialogVisible = true;
      var day = this.selectItem.beginTime
      var time
      var startTime = this.selectItem.beginTime
      for (var i = 0; i < this.tableData[index].data.length; i++) {
        day += this.tableData[index].data[i].partTime
        time = moment(day * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.echartsdata.push(time)
        this.selectItem.data[i].startDay = startTime
        startTime += this.selectItem.data[i].partTime
      }
    },
    // 选中发生变化
    selectionChange(e) {
      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },
  }
}
</script>
<style scoped>
.m-tab-echartTable {
  position: relative;
}
.echartdownLoad {
  position: absolute;
  top: 0;
  left: -2000px;
  width: 800px;
  height: 660px;
}
/* .el-col-12 {
  width: 100%;
} */
.ul-detail li {
  width: 33%;
}

.el-button--primary {
  margin-left: 45px;
  margin-right: 5px;
}

.m-filter .filter .btn {
  margin-left: 2.5rem;
}

.el-checkbox {
  width: 20%;
}

/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.3rem 0 0;
}

.ul-detail li:first-child {
  padding-left: 1.6rem;
}

.ul-detail .s3 .tit {
  min-width: 8em;
}

/* 详情 标签3 */
.m-pop-echart-two {
  display: flex;
  flex-direction: row;
}

.m-pop-echart-two .row {
  flex: 0.5;
}

.m-pop-echart-two .inner {
  flex: 1;
  background: #fff;
}

.m-pop-echart-two .el-col-12 {
  width: 100%;
}
</style>
