<template>
  <div class="row-history">
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item input">
                  <el-input class="input" placeholder="请输入桩号" v-model="number"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <el-main>
        <el-table :data="tableData" style="width: 100%" ref="table" row-key="id" @selection-change="selectionChange" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
          <el-table-column fixed type="selection"></el-table-column>
          <el-table-column fixed prop="pileDescribe" label="孔号" min-width='70px' align='center' show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="deviceKey" label="桩机号" min-width='120px' align='center' show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="beginTime" label="开始时间" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.beginTime|datafilters }}
            </template>
          </el-table-column>
          <el-table-column prop="endTime" label="结束时间" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.endTime|datafilters }}
            </template>
          </el-table-column>
          <el-table-column prop="totalPulp" label="总注浆量(L)" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.totalPulp|numfilters }}
            </template>
          </el-table-column>
          <el-table-column prop="maxPressure" label="最大压力(Mpa)" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.maxPressure|numfilters }}
            </template>
          </el-table-column>
          <el-table-column prop="waterRate" label="透水率(lu)" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.waterRate|numfilters }}
            </template>
          </el-table-column>
          <el-table-column prop="pileTime" label="注浆时长(min)" min-width='120px' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.pileTime|minfilters }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="pileTime" label="成桩时间" sortable width="10%">
                </el-table-column> -->
          <el-table-column fixed="right">
            <template slot-scope="scope">
              <div class="m-edit-btns">
                <div class="item">
                  <a href="javascript:" class="btn" @click="handleCommand(scope.$index)">详情</a>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
    <el-dialog :visible.sync="centerDialogVisible" class="m-pop m-pop-detail">
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
        <div class="right">
          <!-- <el-select class="g-select-table" v-model="selectTable" placeholder="请选择记录表下载">
            <el-option v-for="item in selectTableList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
        </div>
      </div>
      <div class="dialog-content">
        <div class="m-tab-table m-tab-detail" v-show="tab2 === 0">
          <div class="m-table-detail">
            <div class="detailitem">
              <h3>详细数据</h3>
              <div class="box">
                <ul class="ul-detail">
                  <li class="s1">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">机器号</div>
                        <div class="value">{{selectItem.deviceKey}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">开始时间</div>
                        <div class="value">{{selectItem.beginTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">结束时间</div>
                        <div class="value">{{selectItem.endTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">施工单位</div>
                        <div class="value">{{print.worker|worfilter}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">灌浆方法</div>
                        <div class="value">{{print|pulpfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">孔口高程(m)</div>
                        <div class="value">{{print.holeHeight|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">起始深度(m)</div>
                        <div class="value">{{print.beginDepth|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">终止深度(m)</div>
                        <div class="value">{{print.endDepth|numfilters}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="s2">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">累计浆量(L)</div>
                        <div class="value">{{selectItem.totalPulp|num1filters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计灰量(kg)</div>
                        <div class="value">{{selectItem.totalAsh|num1filters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">弃灰量(kg)</div>
                        <div class="value">{{print.disAsh|num1filters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">弃浆量(L)</div>
                        <div class="value">{{print.disGrout|num1filters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">水泥比重(g/cm³)</div>
                        <div class="value">{{print.cementDensity|num2filters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">砂比重(g/cm³)</div>
                        <div class="value">{{print.sandDensity|num2filters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">单位灰量(kg/m)</div>
                        <div class="value">{{selectItem.avgAsh|num1filters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">单位浆量(L/m)</div>
                        <div class="value">{{selectItem.avgPulp|num1filters}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="s4">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">掺加剂1(kg)</div>
                        <div class="value">{{print.densityStuff1|num1filters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">掺加剂2(kg)</div>
                        <div class="value">{{print.densityStuff2|num1filters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">掺加剂3(kg)</div>
                        <div class="value">{{print.densityStuff3|num1filters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">注砂量(kg)</div>
                        <div class="value">{{selectItem.totalSand|num1filters}}</div>
                      </div>

                      <div class="item">
                        <div class="tit">抬动孔号</div>
                        <div class="value">{{print.liftHoleId|worfilter}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">记录间隔(s)</div>
                        <div class="value">{{selectItem.recordInterval|intervalfilters}}</div>
                      </div>
                      <!-- <div class="item">
                        <div class="tit">管容(L)</div>
                        <div class="value">{{print.pipeCapacity|num1filters}}</div>
                      </div> -->
                      <div class="item">
                        <div class="tit">注浆时长(min)</div>
                        <div class="value">{{selectItem.pileTime|minfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">单元号</div>
                        <div class="value">{{print.unitId|worfilter}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="s3">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">部位</div>
                        <div class="value">{{print.area|worfilter}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">泵号</div>
                        <div class="value">{{print.pumpId|worfilter}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">孔序</div>
                        <div class="value">{{print.holeId|worfilter}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">排号</div>
                        <div class="value">{{print.rowId|worfilter}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">段号</div>
                        <div class="value">{{print.partId|worfilter}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">桩号</div>
                        <div class="value">{{print.pileId|worfilter}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">通道</div>
                        <div class="value">{{print.channel}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">孔号</div>
                        <div class="value">{{selectItem.pileDescribe}}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <br />
            <h3>段数据列表</h3>
            <el-table ref="saveTable" class="m-table-save m_table_save_height" :data="selectItem.dataForTable" style="width: 100%;margin-top:20px" height="23em">
              <el-table-column property="startDay" label="时间" min-width="12%" align="center">
              </el-table-column>
              <el-table-column property="partFlow" :label="'流量\nL/min'" min-width="7.5%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partFlow|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partPressure" :label="'压力\nMPa'" min-width="6.9%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partPressure|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partPressurePeak" :label="'峰压力\nMPa'" min-width="7.8%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partPressurePeak|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partFlowIn" :label="'进浆\nL/min'" min-width="7.5%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partFlowIn|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partFlowReturn" :label="'返浆\nL/min'" min-width="7.5%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partFlowReturn|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partDensity" :label="'密度\ng/cm³'" min-width="7.5%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partDensity|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partSandCementRatio" label="砂灰比" min-width="5.0%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partSandCementRatio|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partWaterCementRatio" label="水灰比" min-width="5.0%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partWaterCementRatio|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partPulp" :label="'注浆量\nL'" min-width="6%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partPulp|num1filters }}
                </template>
              </el-table-column>
              <el-table-column property="partAsh" :label="'注灰量\nL'" min-width="6%" align="center">
                <template slot-scope="scope">
                  {{scope.row.partAsh|num1filters}}
                </template>
              </el-table-column>
              <el-table-column property="partStatus" label="备注" min-width="5.5%" align="center">
                <template slot-scope="scope">
                  {{scope.row.partStatus|num3filters}}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 1">
          <div class="m-pop-echart">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart02" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart03" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart04" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-footer class="m-selectAll">
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot, jumper, sizes" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import moment from "moment";
import { historyList } from "@/api/api";
import gTabs from "@/components/gTabs";
import * as echarts from "echarts";
export default {
  components: {
    gTabs
  },
  props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'handleCurrentChange', 'toLastPage', 'toFirstPage', 'pileDescribe'],
  data() {
    return {
      totalChecked: false,
      startDate: '',
      endDate: '',
      number: "",
      deviceKey: '',
      echartsdata: [],
      print: [],
      selectItem: [],
      centerDialogVisible: false,
      tab2: 0,
      tab2List: [
        {
          name: "详细内容",
        },
        {
          name: "图表曲线",
        },
      ],
      selectTable: "记录报表下载",
      selectTableList: [
        {
          label: "记录报表下载",
          value: "0",
        },
        {
          label: "记录报表下载1",
          value: "1",
        },
      ],
      inquire: [],
      totalNum: 0,
      echartDownload: [],
    }
  },
  filters: {
    worfilter(val) {
      if (val != undefined) {
        return val.replace(/\u0000/g, '')
      } else {
        return val;
      }
    },
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val) {
      return parseFloat(val).toFixed(2)
    },
    num1filters(val) {
      return parseFloat(val).toFixed(1)
    },
    num2filters(val) {
      return parseFloat(val).toFixed(3)
    },
    intervalfilters(val) {
      switch (val) {
        case 0:
          return 5
        case 1:
          return 10
        case 2:
          return 30
        case 3:
          return 60
        default:
          return val
      }
    },
    pulpfilters(obj) {
      var processMethod
      switch (obj.paramType) {
        case 0:
          processMethod = '二参数'
          break;
        case 1:
          processMethod = '三参数'
          break;
        case 2:
          processMethod = '四参数'
          break;
        default:
          processMethod = ''
          break;
      }
      switch (obj.processMethod) {
        case 0:
          processMethod += '大循环'
          break;
        case 1:
          processMethod += '小循环'
          break;
        default:
          processMethod += ''
          break;
      }
      return processMethod
    },
    num3filters(val) {
      switch (val) {
        case 0:
          return '工作中'
        case 1:
          return '暂停'
        case 3:
          return '变浆'
        case 4:
          return '信号异常'
        case 5:
          return '开启回浆'
        case 6:
          return '关闭回浆'
        case 7:
          return '间隔灌浆'
        case 8:
          return '浆压限流'
        case 9:
          return '管路故障'
        case 10:
          return '置换结束'
        case 11:
          return '冒浆'
        case 12:
          return '等浆'
        case 13:
          return '串浆'
        case 14:
          return '停电'
        case 15:
          return '其他'
        case 50:
          return '结束'
        default:
          return val
      }
    },
    minfilters(val) {
      return (parseFloat(val) / 60).toFixed(0)
    }
  },
  watch: {
    centerDialogVisible(val, oldval) {
      if (val == false) {
        this.tab2 = 0
        this.selectItem = []
        if (this.myChart01 == undefined) return
        this.myChart01.dispose()
        this.myChart02.dispose()
        this.myChart03.dispose()
        this.myChart04.dispose()
      }
    }
  },
  mounted() {
    this.deviceKey = this.device
    // console.log('historyGJ' + '--------' + sessionStorage['deviceKey']);
  },
  methods: {
    // 图片下载
    // downLoadImage() {
    //   let a = document.createElement('a');
    //   a.href = this.myChart01.getConnectedDataURL({
    //     pixelRatio: 2,
    //     backgroundColor: '#fff'
    //   })
    //   a.download = "图片下载.png";
    //   a.click();
    // },
    // 查询
    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      historyList('GJ', 0, this.deviceKey, this.pageSize, this.number, this.startDate, this.endDate).then((res) => {
        this.inquire = res.content;
        this.totalNum = res.totalElements;
        this.$emit('changeDevicekey', this.deviceKey, this.number, this.startDate, this.endDate, this.inquire, this.totalNum)
        loading.close();
      });
      setTimeout(() => {
        loading.close()
      })
    },
    resetClick() {
      this.deviceKey = "";
      this.number = '', this.startDate = '', this.endDate = ''
    },

    initEchart01() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partPressure];
      });
      let option = {
        title: {
          text: "压力-时间变化曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["压力"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "压力  ",
            type: "value",
            inverse: false,
            axisLine: {
              show: true
            },
            nameLocation: "start",
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
        ],
        series: [
          {
            name: "压力",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart01.setOption(option);
      this.echartDownload.push(this.myChart01)
    },
    initEchart02() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDensity.toFixed(2)];
      });
      let option = {
        title: {
          text: "密度-时间变化曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["密度"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "密度",
            type: "value",
            inverse: false,
            axisLine: {
              show: true
            },
            nameLocation: "start",
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
        ],
        series: [
          {
            name: "密度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart02 = echarts.init(this.$refs.myChart02, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart02.setOption(option);
      this.echartDownload.push(this.myChart02)
    },
    initEchart03() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partFlow.toFixed(2)];
      });
      let option = {
        title: {
          text: "流量-时间变化曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["流量"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "流量",
            type: "value",
            inverse: false,
            axisLine: {
              show: true
            },
            nameLocation: "start",
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
        ],
        series: [
          {
            name: "流量",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart03 = echarts.init(this.$refs.myChart03, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart03.setOption(option);
      this.echartDownload.push(this.myChart03)
    },
    initEchart04() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partWaterRate];
      });
      let option = {
        title: {
          text: "透水率-时间变化曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["透水率"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "透水率",
            type: "value",
            inverse: false,
            axisLine: {
              show: true
            },
            nameLocation: "start",
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
        ],
        series: [
          {
            name: "透水率",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart04 = echarts.init(this.$refs.myChart04, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart04.setOption(option);
      this.echartDownload.push(this.myChart04)
    },
    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index === 1) {
        this.$nextTick(() => {
          this.initEchart01();
          this.initEchart02();
          this.initEchart03();
          this.initEchart04();
          echarts.connect(this.echartDownload)
          window.onresize = function () {
            self.myChart01.resize();
            self.myChart02.resize();
            self.myChart03.resize();
            self.myChart04.resize();
          };
        });
      }
    },
    // 详情点击
    handleCommand(index) {
      // 数据暂存在selectItem中
      this.selectItem = this.tableData[index];
      this.print = this.tableData[index].print
      this.centerDialogVisible = true;
      var day = this.selectItem.beginTime
      var time
      for (var i = 0; i < this.tableData[index].data.length; i++) {
        day += this.tableData[index].data[i].partTime
        time = moment(day * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.echartsdata.push(time)
      }
      var startTime = this.selectItem.beginTime
      for (var j = 0; j < this.selectItem.dataForTable.length; j++) {
        this.selectItem.dataForTable[j].startDay = moment(startTime * 1000).format('YYYY-MM-DD HH:mm:ss')
        startTime += this.selectItem.dataForTable[j].partTime
      }
    },
    // 选中发生变化
    selectionChange(e) {
      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },
  }
}
</script>
<style scoped>
.ul-detail .s3 .tit {
  min-width: 7rem;
}
.ul-detail .inner {
  padding: 0.625rem 0 0.625rem 0.3rem;
}
/* .ul-detail li {
  padding: 0 1rem;
} */
/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.2rem 0 0;
}
.ul-detail li:first-child {
  padding-left: 1.2rem;
}
</style>
